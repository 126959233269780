import { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PersonAddEditForm from "../../form/PersonAddEditForm";
import { Person, schema as PersonSchema } from "../../../models/Person";
import {
  PersonJob,
  schema as PersonJobSchema,
} from "../../../models/PersonJob";
import {
  PersonAddress,
  schema as PersonAddressSchema,
} from "../../../models/PersonAddress";
import {
  PersonVaccine,
  schema as PersonVaccineSchema,
} from "../../../models/PersonVaccine";
import { CancelReason } from "../../../models/CancelReason";
import {
  PersonDocument,
  schema as PersonDocumentSchema,
} from "../../../models/PersonDocument";
import queryString from "query-string";
import { PersonGender } from "../../../enums/PersonGender";
import { FamilyStatus } from "../../../enums/FamilyStatus";
import { DocumentType } from "../../../enums/DocumentType";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PersonInternationalPassport,
  schema as InterPassSchema,
} from "../../../models/PersonInternationalPassport";
import useFetch, { CachePolicies } from "use-http";
import { notificationTemplate } from "../../../App";
import { Store } from "react-notifications-component";
import { useStyles } from "./styles";
import moment from "moment";
import { RoutePaths } from "../../../routes/AppRoutes";

enum Certificate {
  National = "National",
  International = "International",
}

export default function CertificatePersonViewPage() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory()
  const { search } = useLocation();
  const { id } = useParams<{ id: string | undefined }>();
  const [submitDetails, setSubmitDetails] = useState({
    loading: false,
    error: false,
    success: false,
  });

  const [cancelReasonData, setCancelReasonData] = useState<
    Partial<CancelReason>
  >({
    id: null,
    hypersensitivity: false,
    pregnancy: false,
    lowImmunity: false,
    autoimmune: false,
    oldAge: false,
    teenager: false,
    allergic: false,
    other: false,
    otherText: "",
  } as unknown as Partial<CancelReason>);

  const [edit, setEdit] = useState(() => {
    return !id;
  });

  const [originalPerson, setOriginalPerson] = useState(null);
  const [cancelled, setCancelled] = useState(false);
  const [interPassportEnabled, setInterPassportEnabled] = useState(false);
  const token = localStorage.getItem("token");

  const {
    post: postSaveData,
    data: saveData,
    loading: saveLoading,
    error: saveError,
  } = useFetch(process.env.REACT_APP_API, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  });

  const {
    get: fetchExistPerson,
    data: findData,
    loading: findLoading,
  } = useFetch(process.env.REACT_APP_API, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        person: PersonSchema,
        internationalPassport: interPassportEnabled
          ? InterPassSchema.required()
          : null,
        document: PersonDocumentSchema,
        address: PersonAddressSchema,
        job: PersonJobSchema,
        vaccines: yup.array(PersonVaccineSchema),
      })
    ),
    mode: "onChange",
    defaultValues: {
      person: Person.build({
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        inn: "",
        nationalNumber: "",
        birthDay: null,
        gender: "" as PersonGender,
      }),
      internationalPassport: PersonInternationalPassport.build({
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        code: null,
        nationality: "",
        passport: null,
        birthDay: null,
        gender: "" as PersonGender,
      }),
      document: PersonDocument.build({
        id: null,
        documentType: "" as DocumentType.Passport,
        seria: "",
        number: "",
        familyStatus: "" as FamilyStatus,
      }),
      address: PersonAddress.build({
        id: null,
        region: "",
        district: "",
        address: "",
        phone: "",
      }),
      job: PersonJob.build({
        id: null,
        position: "",
        placeOfWork: "",
        occupation: 0,
      }),
      vaccines: [],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "vaccines",
    keyName: "key",
  });

  useEffect(() => {
    if (!id) return;
    fetchExistPerson(`persons/${id}`);
  }, [id]);

  useEffect(() => {
    if (!search) return;
    const parsedPersonAndDocument = queryString.parse(
      search
    ) as unknown as Person & PersonDocument;

    if (!parsedPersonAndDocument) return;
    const person = {
      ...Person.build({
        firstName: parsedPersonAndDocument.firstName,
        lastName: parsedPersonAndDocument.lastName,
        middleName: parsedPersonAndDocument.middleName,
        inn: parsedPersonAndDocument.inn,
        nationalNumber: parsedPersonAndDocument.nationalNumber,
        birthDay: parsedPersonAndDocument.birthDay,
        gender: parsedPersonAndDocument.gender,
      }),
    };

    const document = {
      ...PersonDocument.build({
        documentType: parsedPersonAndDocument.documentType,
        seria: parsedPersonAndDocument.seria,
        number: parsedPersonAndDocument.number,
        familyStatus: parsedPersonAndDocument.familyStatus,
      }),
    };

    setValue("person", person);
    setValue("document", document);
  }, [search]);

  useEffect(() => {
    if (!findData) return;
    const person = {
      ...Person.build({
        id: findData.id,
        firstName: findData.firstName,
        lastName: findData.lastName,
        middleName: findData.middleName,
        inn: findData.inn,
        nationalNumber: findData.nationalNumber,
        birthDay: findData.birthDay,
        gender: findData.gender,
      }),
    };
    const interPass = findData.internationalPassport && {
      ...PersonInternationalPassport.build({
        id: findData.internationalPassport.id,
        firstName: findData.internationalPassport.firstName,
        middleName: findData.internationalPassport.middleName,
        lastName: findData.internationalPassport.lastName,
        code: findData.internationalPassport.code,
        nationality: findData.internationalPassport.nationality,
        passport: findData.internationalPassport.passport,
        birthDay: findData.internationalPassport.birthDay,
        gender: findData.internationalPassport.gender,
      }),
    };

    const document = {
      ...PersonDocument.build({
        id: findData.document.id,
        documentType: findData.document.documentType,
        seria: findData.document.seria,
        number: findData.document.number,
        familyStatus: findData.document.familyStatus,
      }),
    };

    const address = {
      ...PersonAddress.build({
        id: findData.address.id,
        region: findData.address.region,
        district: findData.address.district,
        address: findData.address.address,
        phone: findData.address.phone,
      }),
    };

    const job = {
      ...PersonJob.build({
        id: findData.job.id,
        position: findData.job.position,
        placeOfWork: findData.job.placeOfWork,
        occupation: findData.job.occupation,
      }),
    };

    const cancelReason = findData.cancelReason && {
      ...findData.cancelReason,
      createdDate: undefined,
      updatedDate: undefined,
    };

    const vaccines = findData.vaccines.map((vaccine) => {
      return {
        ...PersonVaccine.build(vaccine),
        user: vaccine.user,
      };
    });

    setOriginalPerson({
      person,
      document,
      interPass,
      address,
      vaccines,
      cancelReason,
      job,
    });
    setValue("person", { ...person });

    if (interPass) {
      setValue("internationalPassport", { ...interPass });
      setInterPassportEnabled(true);
    }

    if (document) {
      setValue("document", { ...document });
    }
    if (address) {
      setValue("address", { ...address });
    }
    if (job) {
      setValue("job", { ...job });
    }

    if (vaccines.length) {
      remove(0);
      append(
        vaccines.map((vaccine) => {
          return {
            ...vaccine,
          };
        })
      );
    } else {
      append(
        PersonVaccine.build({
          id: null,
          seria: "",
          expireDate: null,
          vaccinationDate: null,
          doseNumber: 1,
          vaccineId: null,
        })
      );
    }

    if (cancelReason) {
      setCancelled(true);
      setCancelReasonData({ ...cancelReason });
    }
  }, [findData, id]);

  useEffect(() => {
    if (!saveData) return;
    const notification = {
      ...notificationTemplate,
    };
    if (saveError) {
      notification.type = "danger";
      notification.title = "Ошибка";
      notification.message = saveData.error.message;
    } else {
      notification.type = "success";
      notification.title = "Запись сохранена";
      notification.message = "Запись успешно сохранена";
    }
    Store.addNotification(notification);
    setEdit(false);
  }, [saveError, saveData]);

  const onCancelReasonChange = (data) => setCancelReasonData(data);
  const handleCancel = () => {
    if (edit) {
      setValue("person", { ...originalPerson.person });

      if (originalPerson.interPass) {
        setValue("internationalPassport", { ...originalPerson.interPass });
        setInterPassportEnabled(true);
      }

      if (originalPerson.document) {
        setValue("document", { ...originalPerson.document });
      }
      if (originalPerson.address) {
        setValue("address", { ...originalPerson.address });
      }
      if (originalPerson.job) {
        setValue("job", { ...originalPerson.job });
      }

      if (originalPerson.vaccines.length) {
        setValue(
          "vaccines",
          originalPerson.vaccines.map((vaccine) => {
            return {
              ...vaccine,
            };
          })
        );
      }

      if (originalPerson.cancelReason) {
        setCancelled(true);
        setCancelReasonData({ ...originalPerson.cancelReason });
      }
    }
    setEdit(!edit);
  };

  const onDeleteVaccine = useCallback((index: number) => {
    remove(index);
    setValue(
      "vaccines",
      getValues("vaccines").map((vaccine, index) => {
        return {
          ...vaccine,
          doseNumber: index + 1,
        };
      })
    );
  }, []);

  const handleAddNewVaccine = useCallback(() => {
    const vaccineLimit = fields.length >= 4;
    if (vaccineLimit) {
      Store.addNotification({
        ...notificationTemplate,
        type: "danger",
        title: "Ошибка",
        message: "Нельзя добавить более 4 дозы. Нельзя!",
      });
    } else {
      append(
        PersonVaccine.build({
          id: null,
          seria: "",
          expireDate: null,
          vaccinationDate: null,
          doseNumber: fields.length + 1,
          vaccineId: null,
          vaccineSeriaId: null,
        })
      );
    }
  }, [fields]);

  const onSubmitHandler = ({
    address,
    document,
    internationalPassport,
    job,
    person,
    vaccines,
  }) => {
    setSubmitDetails({
      ...submitDetails,
      loading: true,
    });
    postSaveData("/persons/save", {
      person: {
        ...person,
        birthDay: moment(person.birthDay).format("YYYY-MM-DD"),
      },
      address,
      cancelReason: cancelled ? cancelReasonData : null,
      document,
      vaccines: vaccines.map((vaccine) => {
        return {
          ...vaccine,
          vaccinationDate: moment(vaccine.vaccinationDate).format("YYYY-MM-DD"),
          expireDate: moment(vaccine.expireDate).format("YYYY-MM-DD"),
          user: undefined,
        };
      }),
      job,
      internationalPassport: interPassportEnabled
        ? {
            ...internationalPassport,
            birthDay: moment(internationalPassport.birthDay).format(
              "YYYY-MM-DD"
            ),
          }
        : null,
    })
      .then(() => {
        setSubmitDetails({
          ...submitDetails,
          loading: false,
          success: true,
        });
        history.push(RoutePaths.certificates)
      })
      .catch(() => {
        setSubmitDetails({
          ...submitDetails,
          loading: false,
          error: true,
        });
      });
  };

  if (findLoading) return null;

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "3rem",
        borderRadius: "30px",
      }}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <PersonAddEditForm
          control={control}
          errors={errors}
          handleAddNewVaccine={handleAddNewVaccine}
          setInterPassportEnabled={setInterPassportEnabled}
          toggleRejectVaccine={() => setCancelled(!cancelled)}
          onDeleteVaccine={onDeleteVaccine}
          onCancelReasonChange={onCancelReasonChange}
          setValue={setValue}
          fields={fields}
          watch={watch}
          cancelled={cancelled}
          interPassportEnabled={interPassportEnabled}
          cancelReasonData={cancelReasonData}
          disabled={!edit}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "3rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.buttonPassport}
              onClick={() =>
                window.open(
                  process.env.REACT_APP_CERTIFICATE_URL +
                    "?type=" +
                    Certificate.National.toLowerCase() +
                    "&id=" +
                    findData.uuid,
                  "_blank"
                )
              }
            >
              Нац. паспорт
            </Button>
            {findData && findData.internationalPassport && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.buttonPassport}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_CERTIFICATE_URL +
                      "?type=" +
                      Certificate.International.toLowerCase() +
                      "&id=" +
                      findData.uuid,
                    "_blank"
                  )
                }
              >
                Межд. паспорт
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitDetails.loading || saveLoading || !edit}
              className={classes.buttonCreate}
            >
              Сохранить
            </Button>
            <Button
              onClick={handleCancel}
              type="button"
              variant="contained"
              color="secondary"
              className={classes.buttonDefault}
            >
              {edit ? "Отменить" : "Редактировать"}
            </Button>
          </div>
        </PersonAddEditForm>
      </form>
    </div>
  );
}
