import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import useFetch, { CachePolicies } from "use-http";
import { notificationTemplate } from "../../../App";
import { Store } from "react-notifications-component";
import ConfirmDialog from "../../dialog/confirm-dialog/ConfirmDialog";
import { useStyles } from "./styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import CommonList from "../../list/CommonList";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import React from "react";
import { useTheme } from "@mui/material";
import DeleteOutlineSharp from "@mui/icons-material/DeleteOutlineSharp";

const limit = 10;
const columns = ["Серия"];
export const schema = yup.object().shape({
  seria: yup.string().required(),
  expireDate: yup.date().required(),
});

export default function VaccineViewPage() {
  const { search } = useLocation();
  const { id }: any = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [keyword, setKeyword] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const page = Number(new URLSearchParams(search).get("page")) || 1;

  const [modalOptions, setModalOptions] = useState({
    open: false,
    edit: null,
  });

  const token = localStorage.getItem("token");

  const {
    handleSubmit: handleFormikSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        seria: yup.string().required(),
        expireDate: yup.date().required(),
      })
    ),
    mode: "onChange",
    defaultValues: {
      seria: "",
      expireDate: "",
    },
  });

  const {
    data: seriesResponseData,
    post: getList,
    loading: listLoading,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const { loading: saveLoading, post: saveSeria } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.NETWORK_ONLY,
    }
  );

  const { loading: deleteLoading, delete: deleteVaccine } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.NETWORK_ONLY,
    }
  );

  const formatDate = (date: string) => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const handleGetList = () => {
    getList("vaccines/series/get-by-filter", {
      limit,
      offset: page - 1 > 0 ? (page - 1) * limit : 0,
      keyword,
      vaccineId: Number(id),
    });
  };

  const handleSubmit = useCallback(
    ({ seria, expireDate }) => {
      saveSeria(`/vaccines/series/${id}`, {
        id: modalOptions.edit?.id,
        seria,
        expireDate: moment(expireDate).format("YYYY-MM-DD"),
        vaccineId: Number(id),
      }).then((res) => {
        if (res.error) {
          Store.addNotification({
            ...notificationTemplate,
            type: "danger",
            title: "Ошибка",
            message: "Ошибка при сохранении",
          });
        } else {
          Store.addNotification({
            ...notificationTemplate,
            type: "success",
            title: "Сохранено",
            message: "Запись успешно сохранена",
          });
          const params = new URLSearchParams(search);
          const page = Number(params.get("page")) || 1;
          const newFilter = {
            limit,
            offset: page - 1 > 0 ? (page - 1) * limit : 0,
          };
          reset();
          setModalOptions({
            open: false,
            edit: null,
          });
          handleGetList();
        }
      });
    },
    [modalOptions.edit, seriesResponseData, id, search]
  );

  useEffect(() => {
    handleGetList();
  }, [search]);

  const handleEdit = useCallback(
    (id: number) => {
      const seria = seriesResponseData.list.find((result) => result.id === id);
      setModalOptions({
        open: true,
        edit: seria,
      });
      setValue("expireDate", seria.expireDate);
      setValue("seria", seria.seria);
    },
    [seriesResponseData]
  );

  const confirmRemoveVaccine = useCallback(() => {
    deleteVaccine(`vaccines/series/${deleteId}`).then((res) => {
      if (!res.error) {
        setDeleteId(null);
        handleGetList();
      }
    });
  }, [deleteId, seriesResponseData]);

  const onSearch = useCallback(() => {
    handleGetList();
  }, [search, keyword]);

  const handleCancelForm = useCallback(() => {
    setModalOptions({ open: false, edit: null });
    reset();
  }, []);

  const data = useMemo(() => {
    if (!seriesResponseData || !seriesResponseData.list) return [];
    return seriesResponseData.list.map((seria) => {
      return {
        render: () => {
          return (
            <React.Fragment key={seria.id}>
              <div
                style={{ color: theme.palette.success.main, flex: "0 0 20%" }}
              >
                <h4>{seria.seria}</h4>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{ marginRight: "1rem" }}
                  onClick={() => handleEdit(seria.id)}
                >
                  <CreateSharpIcon />
                </div>
                <div onClick={() => setDeleteId(seria.id)}>
                  <DeleteOutlineSharp color="error" />
                </div>
              </div>
            </React.Fragment>
          );
        },
      };
    });
  }, [seriesResponseData]);

  return (
    <div>
      <Dialog maxWidth="xs" open={modalOptions.open} onClose={handleCancelForm}>
        <form onSubmit={handleFormikSubmit(handleSubmit)}>
          <DialogTitle>
            {modalOptions.edit ? "Редактировать" : "Создать"} серию
          </DialogTitle>
          <DialogContent>
            <div className={classes.mb2}>
              <Controller
                name={`seria`}
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    required
                    id="seria"
                    label="Серия"
                    className={classes.textField}
                    onChange={onChange}
                    value={value}
                    placeholder="Серия"
                    margin="normal"
                    error={!!errors?.seria}
                    ref={ref}
                    helperText={errors && errors.seria?.message}
                  />
                )}
              />
            </div>
            <div className={classes.mb2}>
              <Controller
                name={`expireDate`}
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    id="expireDate"
                    label="Срок годности использования"
                    type="date"
                    value={formatDate(value) || ""}
                    className={classes.textField}
                    onChange={onChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors?.expireDate}
                    helperText={errors && errors.expireDate?.message}
                    ref={ref}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelForm}>Отменить</Button>
            <Button disabled={saveLoading} type="submit">
              Сохранить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog
        title="Удалить серию ?"
        content={`Вы действительно хотите удалить ${
          seriesResponseData?.list.find((vaccine) => vaccine.id === deleteId)
            ?.seria || ""
        } ?`}
        open={!!deleteId}
        handleClose={() => setDeleteId(null)}
      >
        <Button autoFocus onClick={() => setDeleteId(null)}>
          Отменить
        </Button>
        <Button
          disabled={deleteLoading || saveLoading}
          onClick={confirmRemoveVaccine}
        >
          Удалить
        </Button>
      </ConfirmDialog>
      <CommonList
        addLabel="Добавить серию"
        handleAddClick={() => setModalOptions({ open: true, edit: null })}
        columns={columns}
        data={data || []}
        limit={limit}
        totalCount={seriesResponseData?.count}
        setKeyword={setKeyword}
        onSearch={onSearch}
        loading={listLoading}
      />
    </div>
  );
}
