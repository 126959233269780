import "./App.css";
import { useEffect, useState, createContext } from "react";
import { makeStyles } from "@mui/styles";
import { Switch, Route } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { iNotification } from "react-notifications-component";
import {appRoutes, RoutePaths} from "./routes/AppRoutes";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import useTheme from "@mui/material/styles/useTheme";
import clsx from "clsx";
import useFetch from "use-http";
import LoginForm from "./components/form/LoginForm";
import DrawerComponent from "./components/header/drawer/DrawerComponent";
import AppBarComponent from "./components/header/appbar/AppBarComponent";
import EducationalMaterials from "./components/page/educational-materials/EducationalMaterials";
import TechSupport from "./components/page/tech-support/TechSupport";

const drawerWidth = 340;

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      height: "100%",
      "& .MuiContainer-maxWidthLg": {
        maxWidth: "1550px !important",
      },
      "& .MuiContainer-root": {
        maxWidth: "1550px !important",
      },
    },
    root: {
      display: "flex",
      height: "100%",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      //   padding: theme.spacing(0, 1),
      paddingBottom: "2rem",
      paddingTop: "2rem",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
      height: "120px",
    },
    content: {
      height: "100%",
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      [theme.breakpoints.down('sm')]: {
        padding: "0 !important",
      },
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    ml2: {
      marginLeft: "2rem !important",
    },
    mb1: {
      marginBottom: "1rem !important",
    },
    menuItemText: {
      fontFamily: "Montserrat:wght@100&display=swap",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#282828",
      textDecoration: "none",
    },
  };
});

export const notificationTemplate: iNotification = {
  title: "",
  message: "",
  type: null,
  insert: "top",
  container: "bottom-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
  },
};

export const UserContext = createContext(null);

function App() {
  const token = localStorage.getItem("token");
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(true);

  const {
    data: currentUser,
    error: userError,
    loading: userLoading
  } = useFetch(
    `${process.env.REACT_APP_API}/users/current-user`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    },
    []
  );

  const { data: logoutResponse, post: doLogout } = useFetch(
    `${process.env.REACT_APP_API}/users/logout`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }
  );

  useEffect(() => {
    if (!logoutResponse) return;
    localStorage.removeItem("token");
    window.location.reload();
  }, [logoutResponse]);

  if (userError || (!userLoading && !currentUser)) return <LoginForm />;

  return (
    <UserContext.Provider value={currentUser}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent
          open={open}
          toggleDrawer={() => setOpen(!open)}
          currentUser={currentUser}
        />
        <DrawerComponent
          open={open}
          toggleDrawer={() => setOpen(!open)}
          currentUser={currentUser}
          doLogout={() => doLogout()}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Container maxWidth={false} className={classes.container}>
            <Switch>
              {appRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
              <Route exact path={RoutePaths.educationalMaterials} component={EducationalMaterials}/>
              <Route exact path={RoutePaths.techSupport} component={TechSupport} />
            </Switch>
          </Container>
        </main>
      </div>
    </UserContext.Provider>
  );
}

export default App;
