import { makeStyles, useTheme } from "@mui/styles";
import { PersonVaccine } from "../../models/PersonVaccine";
import Button from "@mui/material/Button";
import DeleteOutlineSharp from "@mui/icons-material/DeleteOutlineSharp";
import { Control } from "react-hook-form";
import { useContext } from "react";
import { UserContext } from "../../App";
import { UserRole } from "../../enums/UserRole";
import PersonVaccineItemForm from "./PersonVaccineItemForm";
import { IconButton, Theme, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme: Theme) => ({
  cursorPointer: {
    cursor: "pointer",
  },
  floatRight: {
    float: "right",
  },
  button: {
    margin: theme.spacing(),
  },
  mb2: {
    marginBottom: "1rem",
  },
  title: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  add: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "30px",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
  },
}));

interface IProps {
  personVaccines: PersonVaccine[];
  onAdd: () => void;
  toggleRejectVaccine: () => void;
  setValue: any;
  onDelete: (index: number) => void;
  disabled?: boolean;
  cancelled: boolean;
  errors: any;
  control: Control<any, any>;
}

export default function PersonVaccineForm({
  disabled,
  onAdd,
  onDelete,
  setValue,
  errors,
  control,
  personVaccines,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const currentUser = useContext(UserContext);

  return (
    <div>
      {personVaccines.map((pv, index) => {
        const deleteAvailable = !pv.id || currentUser?.role === UserRole.Admin;
        const disabledVaccine = !pv.id || currentUser?.role !== UserRole.Operator;
        return (
          <div key={pv.id || index}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between",}}>
              <div style={{display: "flex",}}>
                <h4 className={classes.title}>Доза {pv.doseNumber}</h4>
                <Tooltip title={pv.user?.name || ""}>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {deleteAvailable && (
                <div
                  className={`${classes.cursorPointer}`}
                  onClick={() => {
                    if (disabled) return;
                    onDelete(index);
                  }}
                >
                  <DeleteOutlineSharp color={disabled ? "disabled" : "error"} />
                </div>
              )}
            </div>
            <PersonVaccineItemForm
              index={index}
              setValue={setValue}
              disabled={disabled}
              errors={errors}
              control={control}
              disabledVaccine={disabledVaccine}
              deleteAvailable={deleteAvailable}
            />
          </div>
        );
      })}
      <Button
        className={classes.add}
        disabled={disabled}
        onClick={() => onAdd()}
        variant="contained"
      >
        Добавить вакцину
      </Button>
    </div>
  );
}
