import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import RegionRegistry from "../../utils/RegionRegistry";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch, { CachePolicies } from "use-http";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles((theme: any) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  selectMenu: {
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "250px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "30px !important",
    },

    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main}`,
    },
  },
  mb1: {
    marginBottom: "1rem",
  },
  buttonCreate: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      marginLeft: 0,
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `#ffffff !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  buttonCancel: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      backgroundColor: `#FFFFFF !important`,
      color: `#282828 !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
}));

export default function OrganizationAddEditPage() {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { id }: { id?: string } = useParams();
  const token = localStorage.getItem("token");

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        region: yup.string().required(),
        district: yup.string().required(),
        address: yup.string().required(),
      })
    ),
    mode: "onChange",
    defaultValues: {
      name: "",
      region: "",
      district: "",
      address: "",
    },
  });

  const { get: getOrganizationById } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.NETWORK_ONLY,
    }
  );

  const { post: submitSave, loading: saveLoading } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  useEffect(() => {
    if (!id) return;
    getOrganizationById(`organizations/${id}`).then((orgData) => {
      setValue(`name`, orgData.name);
      setValue(`region`, orgData.region);
      setValue(`district`, orgData.district);
      setValue(`address`, orgData.address);
    });
  }, [id]);

  const handleSave = useCallback((args: any) => {
    submitSave("organizations/save", { ...args, id }).then(() => {
      history.push("/organizations");
    });
  }, []);
  
  const regionSelected = watch("region");

  return (
    <div
      style={{
        backgroundColor: "white",
        maxWidth: "380px",
        padding: "40px",
        borderRadius: "30px",
        height: "100%",
      }}
    >
      <div>
        <h4 style={{ fontSize: "18px" }}>Добавить организацию</h4>
      </div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="name"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="name"
                placeholder="Название"
                margin="normal"
                label="Название"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
            )}
          />
        </div>
        <div className={classes.mb1}>
          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="address"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="address"
                placeholder="Адрес"
                margin="normal"
                label="Адрес"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.address}
                helperText={errors?.address?.message}
              />
            )}
          />
        </div>
        <div className={classes.mb1}>
          <FormControl className={`${classes.formControl}`}>
            <InputLabel id="region-label">Город</InputLabel>
            <Controller
              name="region"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  labelId="region-label"
                  value={value || ""}
                  onChange={onChange}
                  id="region"
                  label="Город"
                  required
                  ref={ref}
                  displayEmpty={false}
                  className={classes.selectMenu}
                  error={!!errors?.region}
                  inputProps={{
                    name: "region",
                  }}
                >
                  <MenuItem value="">Не выбрано</MenuItem>
                  {RegionRegistry?.getList()?.map((regionData) => (
                    <MenuItem
                      key={regionData.region.value}
                      value={regionData.region.value}
                    >
                      {regionData.region.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.region && (
              <FormHelperText error>{errors.region?.message}</FormHelperText>
            )}
          </FormControl>
        </div>
        {regionSelected && (
          <div className={classes.mb1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="district-label">Район</InputLabel>
              <Controller
                name="district"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    labelId="district-label"
                    value={value || ""}
                    onChange={onChange}
                    label="Район"
                    required
                    displayEmpty={false}
                    className={classes.selectMenu}
                    ref={ref}
                    id="district-simple"
                    error={!!errors?.district}
                    inputProps={{
                      name: "district",
                    }}
                  >
                    <MenuItem value="">Не выбрано</MenuItem>
                    {RegionRegistry?.getDistrictsByRegion(regionSelected)?.map(
                      (regionData) => (
                        <MenuItem
                          key={regionData.value}
                          value={regionData.value}
                        >
                          {regionData.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
              {errors?.district && (
                <FormHelperText error>
                  {errors.district?.message}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            className={classes.buttonCreate}
            type="submit"
            variant="contained"
            color="primary"
            disabled={saveLoading}
          >
            Сохранить
          </Button>
          <Button
            type="button"
            onClick={() => history.push("/organizations")}
            className={classes.buttonCancel}
            variant="contained"
          >
            Отменить
          </Button>
        </div>
      </form>
    </div>
  );
}
