import { useState, useEffect, useCallback, useContext } from "react";
import { useLocation, useParams, Redirect, useHistory } from "react-router-dom";
import PersonAddEditForm from "../form/PersonAddEditForm";
import { Person, schema as PersonSchema } from "../../models/Person";
import { PersonJob, schema as PersonJobSchema } from "../../models/PersonJob";
import {
  PersonAddress,
  schema as PersonAddressSchema,
} from "../../models/PersonAddress";
import {
  PersonVaccine,
  schema as PersonVaccineSchema,
} from "../../models/PersonVaccine";
import { CancelReason } from "../../models/CancelReason";
import {
  PersonDocument,
  schema as PersonDocumentSchema,
} from "../../models/PersonDocument";
import queryString from "query-string";
import { PersonGender } from "../../enums/PersonGender";
import { FamilyStatus } from "../../enums/FamilyStatus";
import { DocumentType } from "../../enums/DocumentType";
import { RoutePaths } from "../../routes/AppRoutes";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PersonInternationalPassport,
  schema as InterPassSchema,
} from "../../models/PersonInternationalPassport";
import useFetch, { CachePolicies } from "use-http";
import { notificationTemplate, UserContext } from "../../App";
import { Store } from "react-notifications-component";
import moment from "moment";
import { UserRole } from "../../enums/UserRole";
import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConfirmDialog from "../dialog/confirm-dialog/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: "white",
    padding: "1rem 2rem 1rem 2rem",
    borderRadius: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  buttonDefault: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      backgroundColor: `#FFFFFF !important`,
      color: `#282828 !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  buttonCreate: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      marginLeft: 0,
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `#ffffff !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  buttonDelete: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      backgroundColor: `${theme.palette.error.main}`,
      color: `#ffffff`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0 !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexGrow: "1",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ml1: {
    marginLeft: "1rem !important",
  },
  certificateBlock: {
    display: "flex",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb1: {
    marginBottom: "1rem",
  },
  mb2: {
    marginBottom: "2rem",
  },
  mt2: {
    marginTop: "2rem",
  },
  sectionTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#000000",
  },
  buttonBlock: {
    display: "flex",
    marginTop: "1rem",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  },
}));

export default function PersonViewPage() {
  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [submitDetails, setSubmitDetails] = useState({
    loading: false,
    error: false,
    success: false,
  });

  const [cancelReasonData, setCancelReasonData] = useState<
    Partial<CancelReason>
  >({
    id: null,
    hypersensitivity: false,
    pregnancy: false,
    lowImmunity: false,
    autoimmune: false,
    oldAge: false,
    teenager: false,
    allergic: false,
    other: false,
    otherText: "",
  } as unknown as Partial<CancelReason>);

  const [cancelled, setCancelled] = useState(false);
  const [interPassportEnabled, setInterPassportEnabled] = useState(false);
  const token = localStorage.getItem("token");
  const currentUser = useContext(UserContext);

  const {
    post: postSaveData,
    data: saveData,
    loading: saveLoading,
    error: saveError,
  } = useFetch(process.env.REACT_APP_API, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  });

  const { delete: deletePerson, loading: deleteLoading } = useFetch(
    process.env.REACT_APP_API,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.NETWORK_ONLY,
    }
  );

  const {
    get: fetchExistPerson,
    data: findData,
    loading: findLoading,
  } = useFetch(process.env.REACT_APP_API, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        person: PersonSchema,
        internationalPassport: interPassportEnabled
          ? InterPassSchema.required()
          : null,
        document: PersonDocumentSchema,
        address: PersonAddressSchema,
        job: PersonJobSchema,
        vaccines: yup.array(PersonVaccineSchema),
      })
    ),
    mode: "onChange",
    defaultValues: {
      person: Person.build({
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        inn: "",
        nationalNumber: "",
        birthDay: null,
        gender: "" as PersonGender,
      }),
      internationalPassport: PersonInternationalPassport.build({
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        code: null,
        nationality: "",
        passport: null,
        birthDay: null,
        gender: "" as PersonGender,
      }),
      document: PersonDocument.build({
        id: null,
        documentType: "" as DocumentType.Passport,
        seria: "",
        number: "",
        familyStatus: "" as FamilyStatus,
      }),
      address: PersonAddress.build({
        id: null,
        region: "",
        district: "",
        address: "",
        phone: "",
      }),
      job: PersonJob.build({
        id: null,
        position: "",
        placeOfWork: "",
        occupation: 0,
      }),
      vaccines: [],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "vaccines",
    keyName: "key",
  });

  useEffect(() => {
    if (!id) return;
    fetchExistPerson(`persons/${id}`);
  }, [id]);

  useEffect(() => {
    if (!search) return;
    const parsedPersonAndDocument = queryString.parse(
      search
    ) as unknown as Person & PersonDocument;

    if (!parsedPersonAndDocument) return;

    setValue(`person`, {
      ...Person.build({
        firstName: parsedPersonAndDocument.firstName,
        lastName: parsedPersonAndDocument.lastName,
        middleName: parsedPersonAndDocument.middleName,
        inn: parsedPersonAndDocument.inn,
        nationalNumber: parsedPersonAndDocument.nationalNumber,
        birthDay: parsedPersonAndDocument.birthDay,
        gender: parsedPersonAndDocument.gender,
      }),
    });

    setValue("document", {
      ...PersonDocument.build({
        documentType: parsedPersonAndDocument.documentType,
        seria: parsedPersonAndDocument.seria,
        number: parsedPersonAndDocument.number,
        familyStatus: parsedPersonAndDocument.familyStatus,
      }),
    });
  }, [search]);

  useEffect(() => {
    if (findData) {
      setValue(`person`, {
        ...Person.build({
          id: findData.id,
          firstName: findData.firstName,
          lastName: findData.lastName,
          middleName: findData.middleName,
          inn: findData.inn,
          nationalNumber: findData.nationalNumber,
          birthDay: findData.birthDay,
          gender: findData.gender,
        }),
      });
    }

    if (findData?.internationalPassport) {
      setValue("internationalPassport", {
        ...PersonInternationalPassport.build({
          id: findData.internationalPassport.id,
          firstName: findData.internationalPassport.firstName,
          middleName: findData.internationalPassport.middleName,
          lastName: findData.internationalPassport.lastName,
          code: findData.internationalPassport.code,
          nationality: findData.internationalPassport.nationality,
          passport: findData.internationalPassport.passport,
          birthDay: findData.internationalPassport.birthDay,
          gender: findData.internationalPassport.gender,
        }),
      });
      setInterPassportEnabled(true);
    }

    if (findData?.document) {
      setValue("document", {
        ...PersonDocument.build({
          id: findData.document.id,
          documentType: findData.document.documentType,
          seria: findData.document.seria,
          number: findData.document.number,
          familyStatus: findData.document.familyStatus,
        }),
      });
    }
    if (findData?.address) {
      setValue("address", {
        ...PersonAddress.build({
          id: findData.address.id,
          region: findData.address.region,
          district: findData.address.district,
          address: findData.address.address,
          phone: findData.address.phone,
        }),
      });
    }
    if (findData?.job) {
      setValue("job", {
        ...PersonJob.build({
          id: findData.job.id,
          position: findData.job.position,
          placeOfWork: findData.job.placeOfWork,
          occupation: findData.job.occupation,
        }),
      });
    }

    if (findData?.vaccines?.length) {
      remove(0);
      append(
        findData.vaccines.map((vaccine) => {
          return {
            ...PersonVaccine.build(vaccine),
            user: vaccine.user,
          };
        })
      );
    } else {
      append(
        PersonVaccine.build({
          id: null,
          seria: "",
          expireDate: null,
          vaccinationDate: null,
          doseNumber: 1,
          vaccineId: null,
          vaccineSeriaId: null,
        })
      );
    }

    if (findData?.cancelReason) {
      setCancelled(true);
      setCancelReasonData({
        ...findData.cancelReason,
        createdDate: undefined,
        updatedDate: undefined,
      });
    }
  }, [findData, id]);

  useEffect(() => {
    if (!saveData) return;
    const notification = {
      ...notificationTemplate,
    };
    if (saveError) {
      notification.type = "danger";
      notification.title = "Ошибка";
      notification.message = saveData.error.message;
    } else {
      notification.type = "success";
      notification.title = "Запись сохранена";
      notification.message = "Запись успешно сохранена";
    }
    Store.addNotification(notification);
  }, [saveError, saveData]);

  const onCancelReasonChange = (data) => setCancelReasonData(data);
  const onSubmitCancel = () => {
    history.push(RoutePaths.personsFind);
  };

  const onSubmitRemove = () => {
    deletePerson(`/persons/${id}`).then((response) => {
      if (response.error) {
        const notification = {
          ...notificationTemplate,
        };
        notification.type = "danger";
        notification.title = "Ошибка";
        notification.message = "Ошибка при удалении записи";
        Store.addNotification(notification);
      } else {
        setConfirmRemove(false);
        history.push(RoutePaths.personsFind);
      }
    });
  };

  const onDeleteVaccine = useCallback((index: number) => {
    remove(index);
    setValue(
      "vaccines",
      getValues("vaccines").map((vaccine, index) => {
        return {
          ...vaccine,
          doseNumber: index + 1,
        };
      })
    );
  }, []);

  const handleAddNewVaccine = useCallback(() => {
    if (fields.length >= 4) {
      const notification = {
        ...notificationTemplate,
      };
      notification.type = "danger";
      notification.title = "Ошибка";
      notification.message = "Нельзя добавить больше 4 доз!";
      Store.addNotification(notification);
    } else {
      append(
        PersonVaccine.build({
          id: null,
          seria: "",
          expireDate: null,
          vaccinationDate: null,
          doseNumber: fields.length + 1,
          vaccineId: null,
          vaccineSeriaId: null,
        })
      );
    }
  }, [fields]);

  const onSubmitHandler = ({
    address,
    document,
    internationalPassport,
    job,
    person,
    vaccines,
  }) => {
    setSubmitDetails({
      ...submitDetails,
      loading: true,
    });
    postSaveData("/persons/save", {
      person: {
        ...person,
        birthDay: moment(person.birthDay).format("YYYY-MM-DD"),
      },
      address,
      cancelReason: cancelled ? cancelReasonData : null,
      document,
      vaccines: cancelled
        ? []
        : vaccines.map((vaccine) => {
            return {
              ...vaccine,
              vaccinationDate: moment(vaccine.vaccinationDate).format(
                "YYYY-MM-DD"
              ),
              expireDate: moment(vaccine.expireDate).format("YYYY-MM-DD"),
              user: undefined,
            };
          }),
      job,
      internationalPassport: interPassportEnabled
        ? {
            ...internationalPassport,
            birthDay: moment(internationalPassport.birthDay).format(
              "YYYY-MM-DD"
            ),
          }
        : null,
    })
      .then(() => {
        setSubmitDetails({
          ...submitDetails,
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setSubmitDetails({
          ...submitDetails,
          loading: false,
          error: true,
        });
      });
  };

  if (submitDetails.success) {
    return <Redirect to={RoutePaths.personsFind} />;
  }

  if (findLoading) return "Загрузка...";

  return (
    <div className={classes.main}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <PersonAddEditForm
          control={control}
          errors={errors}
          handleAddNewVaccine={handleAddNewVaccine}
          setInterPassportEnabled={setInterPassportEnabled}
          toggleRejectVaccine={() => setCancelled(!cancelled)}
          onDeleteVaccine={onDeleteVaccine}
          onCancelReasonChange={onCancelReasonChange}
          setValue={setValue}
          fields={fields}
          watch={watch}
          cancelled={cancelled}
          interPassportEnabled={interPassportEnabled}
          cancelReasonData={cancelReasonData}
          id={id}
        >
          <div className={classes.buttonBlock}>
            <Button
              type="submit"
              variant="contained"
              disabled={submitDetails.loading || saveLoading}
              className={classes.buttonCreate}
            >
              Сохранить
            </Button>
            <Button
              onClick={onSubmitCancel}
              type="button"
              variant="contained"
              className={`${classes.buttonDefault} ${classes.ml1}`}
            >
              Отменить
            </Button>
            {currentUser?.role === UserRole.Admin && (
              <Button
                onClick={() => setConfirmRemove(true)}
                type="button"
                variant="contained"
                className={`${classes.buttonDelete} ${classes.ml1}`}
              >
                Удалить
              </Button>
            )}
          </div>
        </PersonAddEditForm>
      </form>
      <ConfirmDialog
        open={confirmRemove}
        handleClose={() => setConfirmRemove(false)}
        title="Удалить запись ?"
        content=""
      >
        <Button
          className={classes.buttonDefault}
          onClick={() => setConfirmRemove(false)}
        >
          Отменить
        </Button>
        <Button
          className={classes.buttonDelete}
          disabled={deleteLoading}
          onClick={() => onSubmitRemove()}
        >
          Удалить
        </Button>
      </ConfirmDialog>
    </div>
  );
}
