import { Person } from "./Person";
import * as yup from "yup";

export const schema = yup.object().shape({
  region: yup.string().required(),
  district: yup.string().required(),
  address: yup.string().required(),
  phone: yup.string().optional().notRequired(),
});

export type BuildPersonAddressType = Omit<
  PersonAddress,
  "createdDate" | "updatedDate" | "personId" | "person"
>;
export class PersonAddress {
  id: number;
  createdDate: Date;
  updatedDate: Date;
  region: string;
  district: string;
  address: string;
  phone: string;
  personId: number;
  person: Person;

  static build({
    id,
    region,
    district,
    address,
    phone,
  }: Partial<PersonAddress>): BuildPersonAddressType {
    return Object.assign(new PersonAddress(), {
      id,
      region,
      district,
      address,
      phone,
    });
  }
}
