import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import moment from "moment";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller, Control, useWatch } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import useFetch, { CachePolicies } from "use-http";

const useStyles = makeStyles((theme: Theme) => ({
  cursorPointer: {
    cursor: "pointer",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  selectMenu: {
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "250px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "30px !important",
    },

    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main}`,
    },
  },
  flexBox: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  floatRight: {
    float: "right",
  },
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb1: {
    marginBottom: "1rem",
  },
  ml1: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    paddingLeft: "7px",
  },
}));

interface IProps {
  index: number;
  setValue: any;
  disabled?: boolean;
  errors: any;
  control: Control<any, any>;
  disabledVaccine: boolean;
  deleteAvailable: boolean;
}

export default function PersonVaccineItemForm({
  index,
  setValue,
  control,
  errors,
  disabled,
  disabledVaccine,
  deleteAvailable,
}: IProps) {
  const token = localStorage.getItem("token");
  const theme = useTheme();
  const classes = useStyles(theme);
  const vaccine = useWatch({
    name: `vaccines.${index}`,
    control,
  });
  const formatDate = (date: Date) => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const {
    data: vaccinessResponseData,
    post: getVaccinesByFilter,
    loading,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.CACHE_AND_NETWORK,
  });

  const { data: seriesResponseData, post: getSeriesByFilter } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.CACHE_AND_NETWORK,
    }
  );

  const cachedVaccines = useMemo(() => {
    if (!vaccinessResponseData) return [];
    return vaccinessResponseData.list.map((result) => {
      return {
        id: result.id,
        label: result.name,
      };
    });
  }, [vaccinessResponseData]);

  useEffect(() => {
    getVaccinesByFilter("vaccines/get-by-filter", { limit: 100, offset: 0 });
  }, []);

  useEffect(() => {
    if (!vaccine.vaccineId) return;
    getSeriesByFilter("vaccines/series/get-by-filter", {
      vaccineId: vaccine.vaccineId,
    });
  }, [vaccine.vaccineId]);

  useEffect(() => {
    if (!vaccine.vaccineSeriaId || !seriesResponseData) return;
    const data = seriesResponseData.list.find(
      (seria) => seria.id === vaccine.vaccineSeriaId
    );
    setValue(`vaccines.${index}.expireDate`, data?.expireDate);
    setValue(`vaccines.${index}.seria`, data?.seria);
  }, [vaccine.vaccineSeriaId, seriesResponseData]);

  const handleInputVaccineChange = useCallback(
    (_, value: string, reason: string) => {
      getVaccinesByFilter("vaccines/get-by-filter", {
        limit: 100,
        offset: 0,
        name: value,
      });
    },
    [getVaccinesByFilter]
  );

  const selectedSeria = seriesResponseData?.list.find(
    (seria) => seria.id === vaccine?.vaccineSeriaId
  );

  return (
    <div>
      <div className={`${classes.flexBox} ${classes.mb1}`}>
        <FormControl className={`${classes.formControl}`}>
          <Controller
            name={`vaccines.${index}.vaccineId`}
            control={control}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Autocomplete
                  id="vaccine"
                  className={classes.selectMenu}
                  onInputChange={handleInputVaccineChange}
                  disableClearable={false}
                  disableCloseOnSelect={true}
                  getOptionLabel={(option) => {
                    const selectedVaccine = cachedVaccines?.find(
                      (cv) => cv?.id === option
                    );
                    return selectedVaccine?.label || "";
                  }}
                  onChange={(_, value: number) => onChange(value)}
                  value={value || ""}
                  disabled={disabled || !disabledVaccine || !deleteAvailable}
                  disablePortal
                  defaultValue=""
                  ref={ref}
                  loading={loading}
                  loadingText="Загрузка..."
                  options={cachedVaccines?.map((res) => res.id)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      required
                      label="Вакцина"
                      error={
                        errors &&
                        !!errors.find((_, id) => id === index)?.vaccineId
                      }
                      {...params}
                    />
                  )}
                />
              );
            }}
          />
          {errors && errors.find((_, id) => id === index)?.vaccineId && (
            <FormHelperText error>{"Ошибка!"}</FormHelperText>
          )}
        </FormControl>
      </div>
      <div className={`${classes.flexBox} ${classes.mb1}`}>
        <Controller
          name={`vaccines.${index}.vaccinationDate`}
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              id="vaccinationDate"
              label="Дата вакцинации"
              type="date"
              required
              disabled={disabled || !disabledVaccine}
              ref={ref}
              value={formatDate(value) || ""}
              className={classes.textField}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                errors &&
                !!errors?.find((el, id) => id === index)?.vaccinationDate
              }
              helperText={
                errors && errors?.find((el) => el)?.vaccinationDate?.message
              }
            />
          )}
        />
      </div>
      <div
        className={`${classes.flexBox} ${classes.mb1} ${classes.alignCenter}`}
      >
        <div>
          <Controller
            name={`vaccines.${index}.vaccineSeriaId`}
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <FormControl error={errors && errors[0]?.seria?.message}>
                <InputLabel id="vaccineSeriaId">Серия</InputLabel>
                <Select
                  label="Серия"
                  className={classes.selectMenu}
                  labelId="vaccineSeriaId"
                  id="vaccineSeriaId"
                  required
                  defaultValue=""
                  value={value || ""}
                  disabled={disabled || !vaccine.vaccineId}
                  displayEmpty={false}
                  placeholder="Серия"
                  onChange={onChange}
                  sx={{ width: 300 }}
                  error={errors && !!errors?.find((el) => el)?.seria}
                  ref={ref}
                >
                  {seriesResponseData &&
                    seriesResponseData.list.map((seria, index) => {
                      return (
                        <MenuItem key={seria.id + index} value={seria.id || ""}>
                          {seria.seria}
                        </MenuItem>
                      );
                    })}
                </Select>
                {errors && (
                  <FormHelperText>
                    {errors?.find((el) => el)?.seria?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </div>
        <div className={classes.ml1}>Серия: {vaccine.seria}</div>
      </div>
      <div
        className={`${classes.flexBox} ${classes.mb1} ${classes.alignCenter}`}
      >
        <div>
          <TextField
            id="expireDate"
            label="Срок годности использования"
            type="date"
            size="small"
            disabled={true}
            value={formatDate(selectedSeria?.expireDate) || ""}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.ml1}>
          Срок годности использования: {vaccine?.expireDate}
        </div>
      </div>
    </div>
  );
}
