import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Theme,
} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import {FamilyStatus} from "../../enums/FamilyStatus";
import {DocumentType} from "../../enums/DocumentType";
import FormHelperText from "@mui/material/FormHelperText";
import {Controller, Control} from "react-hook-form";
import {useCallback} from "react";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginTop: '0 !important',
        width: 300,
        "& .MuiOutlinedInput-root": {
            borderRadius: "30px",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    button: {
        margin: theme.spacing(),
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 300,
    },
    flexItems: {
        display: "flex",
        alignItems: "center",
    },
    mb2: {
        marginBottom: "1rem",
    },
    selectMenu: {
        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
            width: "250px",
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
            borderRadius: "30px !important",
        },

        "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
            color: `${theme.palette.primary.main}`,
        },
    },
    hideArrows: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
}));

interface IProps {
    errors: any;
    control: Control<any, any>;
    disabled?: boolean;
    documentType?: DocumentType.BirthCertificate | DocumentType.Passport | "";
}

export default function PersonDocumentForm({
                                               errors,
                                               control,
                                               disabled = false,
                                               documentType,
                                           }: IProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const location = useLocation();

    const handleChange = useCallback((value, onChange) => {
        if (!value) {
            onChange("");
            return;
        }
        if (isNaN(Number(value))) {
            if (value.length === 1) {
                onChange("");
                return;
            }
            onChange(value.slice(0, value.length - 1));
            return;
        }
        onChange(value);
    }, []);

    return (
        <div>
            <div className={classes.mb2}>
                <Controller
                    name="document.documentType"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <FormControl className={classes.formControl}>
                            <InputLabel style={{top: '-8px'}} id="documentType-simple">
                                Тип удостоверяющих документов *
                            </InputLabel>
                            <Select
                                labelId="documentType-simple"
                                id="documentType-simple"
                                size='small'
                                value={value}
                                onChange={onChange}
                                required
                                autoWidth
                                label="Тип удостоверяющих документов *"
                                className={classes.selectMenu}
                                disabled={disabled}
                                inputProps={{
                                    name: "documentType",
                                }}
                                ref={ref}
                            >
                                <MenuItem value="">Не выбрано</MenuItem>
                                <MenuItem value={DocumentType.BirthCertificate}>
                                    Свидетельство о рождения
                                </MenuItem>
                                <MenuItem value={DocumentType.Passport}>Паспорт</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
                {errors?.documentType && (
                    <FormHelperText error>{errors.documentType?.message}</FormHelperText>
                )}
            </div>
            <div className={classes.mb2}>
                <Controller
                    name="document.familyStatus"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <FormControl className={classes.formControl}>
                            <InputLabel style={{top: '-8px'}} id="familyStatus-simple">
                                Семейное положение
                            </InputLabel>
                            <Select
                                labelId="familyStatus-simple"
                                label="Семейное положение"
                                size="small"
                                value={value}
                                onChange={onChange}
                                autoWidth
                                id="familyStatus-simple"
                                className={classes.selectMenu}
                                disabled={disabled}
                                inputProps={{
                                    name: "familyStatus",
                                }}
                                ref={ref}
                            >
                                <MenuItem value="">Не выбрано</MenuItem>
                                <MenuItem value={FamilyStatus.Married}>Женат/Замужем</MenuItem>
                                <MenuItem value={FamilyStatus.Single}>
                                    Холост/Не замужем
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
                {errors?.familyStatus && (
                    <FormHelperText error>{errors.familyStatus?.message}</FormHelperText>
                )}
            </div>
            <div>
                <Controller
                    name="document.seria"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            required
                            id="standard-required"
                            label="Серия"
                            size="small"
                            ref={ref}
                            className={classes.textField}
                            onChange={onChange}
                            value={value}
                            name="seria"
                            type="text"
                            margin="normal"
                            inputProps={{
                                maxLength: documentType === DocumentType.Passport ? 1 : 2,
                            }}
                            error={!!errors?.seria}
                            helperText={errors?.seria?.message}
                            disabled={disabled && location?.pathname.includes('certificates')}
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    name="document.number"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            required
                            id="standard-required"
                            label="Номер"
                            size="small"
                            className={`${classes.textField} ${classes.hideArrows}`}
                            onChange={(e) => handleChange(e.target.value, onChange)}
                            value={value}
                            ref={ref}
                            name="number"
                            margin="normal"
                            inputProps={{
                                pattern: "[0-9]*"
                            }}
                            type="text"
                            inputMode="numeric"
                            error={!!errors?.number}
                            helperText={errors?.number?.message}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
        </div>
    );
}
