import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormHelperText,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { User } from "../../models/User";
import { makeStyles, useTheme } from "@mui/styles";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import useFetch, { CachePolicies } from "use-http";
import { notificationTemplate } from "../../App";
import { Store } from "react-notifications-component";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserRole } from "../../enums/UserRole";

const useStyles = makeStyles((theme: any) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  selectMenu: {
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "250px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "30px !important",
    },

    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main}`,
    },
  },
  mb1: {
    marginBottom: "1rem",
  },
  buttonCreate: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      marginLeft: 0,
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `#ffffff !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  buttonCancel: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      backgroundColor: `#FFFFFF !important`,
      color: `#282828 !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
}));

export default function OperatorAddEditPage() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { id }: { id?: string } = useParams();
  const history = useHistory();
  const [openRoleSelect, setOpenRoleSelect] = useState(false);
  const [openOrganizationSelect, setOpenOrganizationSelect] = useState(false);

  const [user, setUser] = useState<Partial<User>>({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    login: "",
    organizationId: null,
    password: "",
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        lastName: yup.string().required(),
        firstName: yup.string().required(),
        middleName: yup.string(),
        role: yup
          .string()
          .oneOf([
            UserRole.Admin,
            UserRole.Operator,
            UserRole.CentralOffice,
            "",
          ])
          .required(),
        phone: yup.string(),
        login: yup.string().required(),
        password: yup.string().required(),
        organizationId: yup.number().required(),
      })
    ),
    mode: "onChange",
    defaultValues: {
      lastName: "",
      firstName: "",
      middleName: "",
      role: "",
      phone: "",
      login: "",
      organizationId: null,
      password: "",
    },
  });

  const token = localStorage.getItem("token");

  const {
    data: organizationsData,
    loading: organizationsLoading,
    post: fetchOrganizations,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const { data: userData, get: getUserById } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.NETWORK_ONLY,
    }
  );

  const {
    data: response,
    post: postSaveUser,
    error: saveError,
    loading: saveLoading,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    fetchOrganizations("organizations/find-by-filter", {
      limit: 100,
      offset: 0,
    });
    const handler = () => {
      setOpenOrganizationSelect(false);
    };

    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  useEffect(() => {
    if (!id) return;
    getUserById(`users/${id}`).then((user) => {
      setValue(`lastName`, user.lastName);
      setValue(`firstName`, user.firstName);
      setValue(`middleName`, user.middleName);
      setValue(`phone`, user.phone);
      setValue(`login`, user.login);
      setValue(`organizationId`, user.organizationId);
      setValue("role", user.role);
    });
  }, [id]);

  useEffect(() => {
    if (!userData) return;
    setUser(userData);
  }, [userData]);

  useEffect(() => {
    if (!response) return;
    const notification = {
      ...notificationTemplate,
    };
    if (saveError) {
      notification.type = "danger";
      notification.title = "Ошибка";
      notification.message = response.error.message;
    } else {
      notification.type = "success";
      notification.title = "Запись сохранена";
      notification.message = "Запись успешно сохранена";
    }
    Store.addNotification(notification);
  }, [saveError, response]);

  const cachedOrganizations = useMemo(() => {
    if (!organizationsData) return [];
    return organizationsData.list.map((result) => {
      return {
        id: result.id,
        label: result.name,
      };
    });
  }, [organizationsData]);

  const handleInputNameChange = useCallback(
    (_, value: string, reason: string) => {
      fetchOrganizations("organizations/find-by-filter", {
        limit: 10,
        offset: 0,
        keyword: value,
      });
    },
    [fetchOrganizations]
  );

  const handleSave = useCallback(
    (args: any) => {
      postSaveUser("users/save", { ...args, id }).then(() => {
        history.push("/operators");
      });
    },
    [id]
  );

  return (
    <div
      style={{
        backgroundColor: "white",
        maxWidth: "1120px",
        padding: "40px",
        borderRadius: "30px",
        margin: "0 auto",
      }}
    >
      <Typography gutterBottom variant="h5">
        {id ? "Редактирование" : "Добавление"} оператора
      </Typography>
      <form
        style={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "0.5rem",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit(handleSave)}
      >
        <div>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="lastName"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="lastName"
                placeholder="Фамилия"
                margin="normal"
                label="Фамилия"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.lastName}
                helperText={errors?.lastName?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="firstName"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="firstName"
                placeholder="Имя"
                margin="normal"
                label="Имя"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.firstName}
                helperText={errors?.firstName?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="middleName"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="middleName"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="middleName"
                placeholder="Отчество"
                margin="normal"
                label="Отчество"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.middleName}
                helperText={errors?.middleName?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="phone"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="phone"
                placeholder="Телефон"
                margin="normal"
                label="Телефон"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.phone}
                helperText={errors?.phone?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="login"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="login"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="login"
                placeholder="Логин"
                margin="normal"
                label="Логин"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.login}
                helperText={errors?.login?.message}
              />
            )}
          />
        </div>
        <div className={classes.mb1}>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="password"
                className={classes.textField}
                onChange={onChange}
                value={value}
                name="password"
                placeholder="Пароль"
                margin="normal"
                label="Пароль"
                autoComplete="none"
                type="text"
                ref={ref}
                error={!!errors?.password}
                helperText={errors?.password?.message}
              />
            )}
          />
        </div>
        <div style={{ flex: "1 25%" }} className={classes.mb1}>
          <FormControl
            style={{ width: "100%" }}
            className={`${classes.formControl}`}
          >
            <Controller
              name={`organizationId`}
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <Autocomplete
                    id="organizationId"
                    open={openOrganizationSelect}
                    onMouseEnter={() => setOpenOrganizationSelect(true)}
                    onClose={() => setOpenOrganizationSelect(false)}
                    className={classes.selectMenu}
                    componentsProps={{
                      paper: {
                        onMouseLeave: () => setOpenOrganizationSelect(false),
                      },
                    }}
                    onInputChange={handleInputNameChange}
                    disableClearable={false}
                    getOptionLabel={(option) => {
                      const selectedVaccine = cachedOrganizations.find(
                        (cv) => cv.id === option
                      );
                      return selectedVaccine?.label || "";
                    }}
                    onChange={(_, value: number) => onChange(value)}
                    value={value || ""}
                    disablePortal
                    ref={ref}
                    loading={organizationsLoading}
                    loadingText="Загрузка..."
                    options={cachedOrganizations.map((res) => res.id)}
                    sx={{ minWidth: 300, maxWidth: "100%" }}
                    renderInput={(params) => (
                      <TextField required {...params} label="Организация" />
                    )}
                  />
                );
              }}
            />
            {errors?.organizationId && (
              <FormHelperText error>
                {errors.organizationId?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div style={{ flex: "1 25%" }}>
          <FormControl
            style={{ width: "100%" }}
            className={classes.formControl}
          >
            <InputLabel id="role-simple">Роль *</InputLabel>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  labelId="role-label"
                  label="Роль *"
                  id="role-simple"
                  className={classes.selectMenu}
                  value={value}
                  onChange={onChange}
                  required
                  open={openRoleSelect}
                  onMouseEnter={() => setOpenRoleSelect(true)}
                  onClose={() => setOpenRoleSelect(false)}
                  onOpen={() => setOpenRoleSelect(true)}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: () => setOpenRoleSelect(false),
                    },
                    variant: "menu",
                  }}
                  autoWidth
                  inputProps={{
                    name: "role",
                  }}
                  error={!!errors?.role}
                  ref={ref}
                >
                  <MenuItem value="">Не выбрано</MenuItem>
                  <MenuItem value={UserRole.Admin}>Администратор</MenuItem>
                  <MenuItem value={UserRole.Operator}>Оператор</MenuItem>
                  <MenuItem value={UserRole.CentralOffice}>
                    Центральный аппарат
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
          {errors?.role && (
            <FormHelperText error>{errors.role?.message}</FormHelperText>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "1rem",
            flex: "1 100%",
          }}
        >
          <Button
            className={classes.buttonCreate}
            type="submit"
            variant="contained"
            color="primary"
            disabled={saveLoading}
          >
            Сохранить
          </Button>
          <Button
            type="button"
            onClick={() => history.push("/operators")}
            className={classes.buttonCancel}
            variant="contained"
          >
            Отменить
          </Button>
        </div>
      </form>
    </div>
  );
}
