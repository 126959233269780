import { DocumentType } from "../enums/DocumentType";
import { Person } from "./Person";
import { FamilyStatus } from "../enums/FamilyStatus";
import * as yup from "yup";

export const schema = yup.object().shape({
  documentType: yup
    .string()
    .oneOf([DocumentType.BirthCertificate, DocumentType.Passport, ""])
    .required(),
  seria: yup.string().when("documentType", {
    is: (value) => value === DocumentType.Passport,
    then: yup
      .string()
      .matches(/[A-Za-z]/, "Только буквы")
      .length(1)
      .required(),
    otherwise: yup.string().length(2).required(),
  }),
  number: yup.string().when("documentType", {
    is: (value) => value === DocumentType.Passport,
    then: yup.string().min(7).max(8).required(),
    otherwise: yup.string().min(6).max(8).required(),
  }),
  familyStatus: yup
    .string()
    .oneOf([FamilyStatus.Married, FamilyStatus.Single, ""]),
});

export type BuildPersonDocumentType = Omit<
  PersonDocument,
  "createdDate" | "updatedDate" | "personId" | "person"
>;

export class PersonDocument {
  id: number;
  createdDate?: Date;
  updatedDate?: Date;
  documentType: DocumentType;
  seria: string;
  number: string;
  personId: number;
  person: Person;
  familyStatus: FamilyStatus;

  static build({
    id,
    documentType,
    seria,
    number,
    personId,
    familyStatus,
  }: Partial<PersonDocument>): BuildPersonDocumentType {
    return Object.assign(new PersonDocument(), {
      id,
      documentType,
      seria,
      number,
      personId,
      familyStatus,
    });
  }
}
