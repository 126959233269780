import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import useFetch, {CachePolicies} from "use-http";
import {useStyles} from "./styles";
import {useCallback} from "react";
import moment, {Moment} from "moment";
import CloudDownloadSharpIcon from "@mui/icons-material/CloudDownloadSharp";
import RegionRegistry from "../../../../utils/RegionRegistry";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type ReportType =
  | "general"
  | "operators"
  | "organizations"
  | "regionVaccination"
  | "unpaidRegionVaccination"
  | "reportPerVaccine"
  | "reportByAge";
type StatisticData = {
  displayText: string;
  value: number;
  count: number;
};

type ReportOptions = {
  displayText: string;
  type: ReportType;
};

const gradients = {
  1: "linear-gradient(121deg,#7EEB7C 31.23%,#2BC155 108.38%)",
  2: "linear-gradient(121deg,#BB92FF 31.23%, #A336C9 108.38%)",
  3: "linear-gradient(113.24deg, #FF968F 16.01%, #E84141 106.71%)",
  4: "linear-gradient(121deg, #EB5757 11.23%, #F2994A 58.38%)",
  5: "linear-gradient(270deg, #EB5757 11.23%, #F2994A 68.38%)",
  // 5: "linear-gradient(to top, #505285 0%, #585e92 12%, #65689f 25%, #7474b0 37%, #7e7ebb 50%, #8389c7 62%, #9795d4 75%, #a2a1dc 87%, #b5aee4 100%)",
}


const cardStatistics: StatisticData[] = [
  {
    displayText: "Полностью вакцинированных",
    value: 1,
    count: 0,
  },
  {
    displayText: "Не полностью вакцинированных",
    value: 2,
    count: 0,
  },
  {
    displayText: "Отказавшиеся от вакцины",
    value: 3,
    count: 0,
  },
  {
    displayText: "Количество граждан",
    value: 4,
    count: 0,
  },
];

const reportButtons: ReportOptions[] = [
  {
    displayText: "Шаблон журнала",
    type: "general",
  },
  {
    displayText: "Отчет по вводу операторов",
    type: "operators",
  },
  {
    displayText: "Отчет по вводу учреждений",
    type: "organizations",
  },
  {
    displayText: "Вакцинация по районам",
    type: "regionVaccination",
  },
  {
    displayText: "Отчет по возрастам",
    type: "reportByAge",
  },
  {
    displayText: "Отчет по вакцинам",
    type: "reportPerVaccine",
  },
];

type ReportOptionState = {
  open: boolean;
  type: ReportType;
  from: string;
  to: string;
  loading: boolean;
};

export default function AdminDashboardPage() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [reportOptions, setReportOptions] = useState<ReportOptionState>({
    open: false,
    type: null,
    from: null,
    to: null,
    loading: false,
  });

  const token = localStorage.getItem("token");
  const defaultHeaders = {
    "Content-Type": "application/json;charset=utf-8",
    Authorization: `Bearer ${token}`,
  };
  const {data: statData, loading: statLoading} = useFetch(
    `${process.env.REACT_APP_API}/admin/get-vaccinated-statistic`,
    {
      headers: defaultHeaders,
    },
    []
  );
  const {data: regionStat, loading: regionStatLoading} = useFetch(
    `${process.env.REACT_APP_API}/admin/get-region-vaccinated-statistic`,
    {
      headers: defaultHeaders,
    },
    []
  );

  const {
    data: exportData,
    loading: exportLoading,
    post: postLoadReport,
  } = useFetch(
    `${process.env.REACT_APP_API}/files/export-excel?type=${reportOptions.type}&dateFrom=${reportOptions.from}&dateTo=${reportOptions.to}`,
    {
      method: "POST",
      headers: defaultHeaders,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const {loading: fetchReportLoading, get: getReportFile} = useFetch(
    `${process.env.REACT_APP_UPLOADS}`,
    {
      method: "GET",
      headers: defaultHeaders,
      responseType: "blob",
    }
  );

  const reportStatistic = useMemo(() => {
    if (!statData || !statData?.data) return [];
    return cardStatistics?.map((cs) => {
      const newStat = {...cs};
      switch (cs?.value) {
        case 1:
          newStat.count = statData ? statData.data.fullCount : 0;
          break;
        case 2:
          newStat.count = statData ? statData.data.halfCount : 0;
          break;
        case 3:
          newStat.count = statData ? statData.data.cancelCount : 0;
          break;
        case 4:
          newStat.count = statData ? statData.data.totalPersonsCount : 0;
          break;
      }
      return newStat;
    });
  }, [statData]);

  useEffect(() => {
    if (exportLoading) return;
    if (!exportData) return;
    const a = document.createElement("a"); //Create <a>
    a.href = "data:application/vnd.ms-excel;base64," + exportData; //Image Base64 Goes here
    a.download = "file.xlsx"; //File name Here
    a.click(); //Downloaded file
  }, [exportData, exportLoading]);

  useEffect(() => {
    if ((exportLoading || fetchReportLoading) && !reportOptions.loading) {
      setReportOptions({
        ...reportOptions,
        loading: true,
      });
    } else {
      if (reportOptions.loading && exportData) {
        setReportOptions({
          ...reportOptions,
          loading: false,
        });
      }
    }
  }, [reportOptions, exportLoading, fetchReportLoading, exportData]);

  const clearReportOptions = () => {
    setReportOptions({
      open: false,
      type: null,
      from: null,
      to: null,
      loading: false,
    });
  };
  const downloadReport = async () => {
    await postLoadReport();
  };
  const displayPicker = (reportOptions: ReportOptionState) => {
    if (
      [
        "general",
        "regionVaccination",
        "unpaidRegionVaccination",
        "reportByAge",
        "reportPerVaccine",
      ].includes(reportOptions?.type)
    ) {
      return (
        <List>
          <ListItem button>
            <TextField
              id="dateFrom"
              label="Дата с"
              type="date"
              onChange={(event) =>
                setReportOptions({
                  ...reportOptions,
                  from: event.target.value,
                })
              }
              value={reportOptions?.from || ""}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </ListItem>
          <ListItem button>
            <TextField
              id="dateTo"
              label="Дата по"
              type="date"
              value={reportOptions?.to || ""}
              onChange={(event) =>
                setReportOptions({
                  ...reportOptions,
                  to: event.target.value,
                })
              }
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </ListItem>
        </List>
      );
    } else if (["operators", "organizations"].includes(reportOptions?.type)) {
      return (
        <List>
          <ListItem button>
            <DatePicker
              views={["year", "month"]}
              label="Год и месяц"
              value={moment(reportOptions?.from)}
              disableFuture={true}
              onChange={(e: Moment) => {
                setReportOptions({
                  ...reportOptions,
                  from: e.startOf("month").format("YYYY-MM-DD"),
                  to: e.endOf("month").format("YYYY-MM-DD"),
                });
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </ListItem>
        </List>
      );
    }
  };

  const periodDialog = useCallback(
    () => (
      <Dialog
        onClose={() => clearReportOptions()}
        aria-labelledby="simple-dialog-title"
        open={true}
      >
        <DialogTitle id="simple-dialog-title">Выберите период</DialogTitle>
        {displayPicker(reportOptions)}
        <DialogActions>
          <Button
            disabled={
              !reportOptions?.from || !reportOptions?.to || reportOptions.loading
              || (moment(reportOptions?.from).isAfter(moment().format())
                && moment(reportOptions?.to).isAfter(moment().format()))
            }
            onClick={() => downloadReport()}
            color="primary"
          >
            Скачать
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [reportOptions]
  );

  return (
    <div style={{paddingBottom: "2rem", marginTop: "-30px"}}>
      {reportOptions.open && periodDialog()}
      <div style={{
        padding: "1rem 1.5rem",
        border: "5px solid rgb(172 205 183)",
        borderRadius: "1rem",
        marginBottom: "1rem",
        backgroundColor: "white",
        minHeight: "210px"
      }}>
        <h2 style={{textAlign: "center", color: "#2BC155"}}>Количество вакцинированных</h2>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
          {reportStatistic?.map((card, index) => {
            return (
              <Card
                sx={{
                  background: gradients[index + 1],
                  color: "white",
                }}
                className={`${classes.cardPaper} ${index > 0 ? classes.ml1 : ""}`}
                key={card?.value}
              >
                <CardContent>
                  <Typography
                    sx={{
                      color: "white",
                    }}
                    color="secondary"
                    gutterBottom
                  >
                    {card?.displayText}
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                    }}
                    variant="h5"
                    component="div"
                  >
                    {card?.count}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>

      </div>
      <div style={{
        border: "5px solid rgb(172 205 183)",
        borderRadius: "1rem",
        padding: "0 1.5rem",
        marginBottom: "1rem",
        backgroundColor: "white",
      }}>
        <h2 style={{textAlign: "center", color: "#2BC155"}}>
          Отчёт по городам / областьям
        </h2>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "120px",
        }}>

          {regionStat?.data?.map((region, i, array) => {
            const matchRegion = RegionRegistry?.getList()?.find(
              (result) => result.region.value === region?.region
            );
            return (
              <Card
                sx={{
                  marginBottom: "1rem",
                  marginRight: i === 4 ? "" : "1rem",
                  borderRadius: "20px",
                  backgroundImage: gradients[i + 1],
                  width: "100%"
                }}
                key={region?.region}
              >
                <CardContent>
                  <Typography color="white" gutterBottom>{matchRegion?.region?.name}</Typography>
                  <Typography
                    sx={{color: "white", fontWeight: "bold"}}
                    variant="h5"
                    component="div"
                  >
                    {region?.count}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          border: "5px solid rgb(172 205 183)",
          borderRadius: "1rem",
          padding: "0 1.5rem 0.5rem",
          marginBottom: "1rem",
          gridColumnGap: "0.5rem",
          backgroundColor: "white",
        }}
      >
        <h2 style={{gridColumn: "1 / 5", textAlign: "center", color: "#2BC155"}}>Общие отчёты</h2>
        {reportButtons?.map((report, i) => {
          return (
            <div style={{gridColumn: i === 6 ? "3/5" : ""}} key={report?.type}>
              <Button
                startIcon={<CloudDownloadSharpIcon/>}
                className={classes.buttonReport}
                onClick={() =>
                  setReportOptions({
                    ...reportOptions,
                    type: report?.type,
                    open: true,
                  })
                }
                variant="contained"
              >
                {report?.displayText}
              </Button>
            </div>
          );
        })}
        <h2 style={{gridColumn: "1 / 5", textAlign: "center", color: "#2BC155"}}>Неоплачиваемые</h2>
        <Button
                startIcon={<CloudDownloadSharpIcon/>}
                className={classes.buttonReport}
                onClick={() =>
                  setReportOptions({
                    ...reportOptions,
                    type: "unpaidRegionVaccination",
                    open: true,
                  })
                }
                variant="contained"
              >
                Вакцинация по районам
              </Button>
      </div>
    </div>
  );
}
