import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import useFetch, { CachePolicies } from "use-http";
import CommonList from "../../list/CommonList";
import React from "react";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useStyles } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { notificationTemplate } from "../../../App";
import { Store } from "react-notifications-component";

const columns = ["Название вакцины"];
const limit = 20;

export default function VaccineListPage() {
  const { search } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const token = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [modalOptions, setModalOptions] = useState({
    open: false,
    edit: null,
  });
  const page = Number(new URLSearchParams(search).get("page")) || 1;

  const paramsObj = {
    limit: String(limit),
    offset: String(page - 1 > 0 ? (page - 1) * limit : 0),
    name: new URLSearchParams(search).get("name") || name,
  };
  const params = new URLSearchParams(paramsObj).toString();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        paid: yup.boolean().required(),
      })
    ),
    mode: "onChange",
    defaultValues: {
      name: "",
      paid: true,
    },
  });

  const {
    data: vaccinessResponseData,
    post: getList,
    loading: listLoading,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const { loading: saveLoading, post: saveVaccine } = useFetch(
    `${process.env.REACT_APP_API}`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      cachePolicy: CachePolicies.NETWORK_ONLY,
    }
  );

  const loadList = () => {
    const params = new URLSearchParams(search);
    const page = Number(params.get("page")) || 1;
    const newFilter = {
      limit,
      offset: page - 1 > 0 ? (page - 1) * limit : 0,
      name,
    };
    getList("vaccines/get-by-filter", newFilter);
  };

  useEffect(() => {
    loadList();
  }, [search]);

  const onSearch = useCallback(() => {
    loadList();
  }, [search, name]);

  const onSubmit = useCallback(
    (args: { name: string }) => {
      saveVaccine("vaccines", { ...args, id: modalOptions.edit?.id }).then(
        (response) => {
          setModalOptions({ open: false, edit: null });
          const notification = {
            ...notificationTemplate,
          };
          reset();
          if (response.error) {
            notification.type = "danger";
            notification.title = "Ошибка";
            notification.message = response.error.message;
          } else {
            notification.type = "success";
            notification.title = "Запись сохранена";
            notification.message = "Запись успешно сохранена";
          }
          Store.addNotification(notification);
          loadList();
        }
      );
    },
    [modalOptions.edit]
  );

  const data = useMemo(() => {
    if (!vaccinessResponseData || !vaccinessResponseData.list) return [];
    return vaccinessResponseData.list.map((vaccine) => {
      return {
        render: () => {
          return (
            <React.Fragment key={vaccine.id}>
              <div
                style={{
                  color: theme.palette.success.main,
                  flex: "0 0 20%",
                  cursor: "pointer",
                }}
                onClick={() => history.push(`/vaccines/${vaccine.id}/series`)}
              >
                <h4>{vaccine.name}</h4>
              </div>
              <div
                onClick={() => {
                  setModalOptions({
                    open: true,
                    edit: vaccine,
                  });
                  setValue("name", vaccine.name);
                  setValue("paid", vaccine.paid);
                }}
              >
                <CreateSharpIcon />
              </div>
            </React.Fragment>
          );
        },
      };
    });
  }, [vaccinessResponseData]);

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={modalOptions.open}
        onClose={() => {
          setModalOptions({ open: false, edit: null });
          setValue("name", "");
          setValue("paid", true);
        }}
      >
        <DialogTitle>
          {modalOptions.edit ? "Редактировать" : "Создать"} вакцину
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  id="name"
                  className={classes.textField}
                  onChange={onChange}
                  value={value}
                  name="name"
                  placeholder="Название вакцины"
                  margin="normal"
                  label="Название вакцины"
                  autoComplete="none"
                  type="text"
                  ref={ref}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                />
              )}
            />
            <Controller
              name="paid"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      id="paid"
                      className={classes.textField}
                      onChange={onChange}
                      value={value}
                      name="paid"
                      ref={ref}
                      checked={value}
                    />
                  }
                  label="Оплачиваемая"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setModalOptions({ open: false, edit: null })}
            >
              Отменить
            </Button>
            <Button disabled={saveLoading} type="submit">
              Сохранить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CommonList
        addLabel="Добавить вакцину"
        handleAddClick={() => setModalOptions({ open: true, edit: null })}
        columns={columns}
        data={data || []}
        limit={limit}
        totalCount={vaccinessResponseData?.count}
        setKeyword={setName}
        onSearch={onSearch}
        loading={listLoading}
      />
    </>
  );
}
