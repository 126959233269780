import {Button, Pagination, Theme, useTheme} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import TextField from "@mui/material/TextField";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { useCallback } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  searchInput: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      border: null,
      borderStartEndRadius: "0",
      borderStartStartRadius: "30px",
      borderEndStartRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  buttonCreate: {
    "&.MuiButtonBase-root.MuiButton-root": {
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `#ffffff !important`,
      borderRadius: "30px",
      width: "320px",
      fontFamily: "Montserrat&display=swap",
      height: "56px",
      textTransform: "none",
      fontSize: "14px",
      fontWeight: 600,
    },
  },
  searchButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `#ffffff !important`,
      border: null,
      borderBottomRightRadius: "30px",
      borderTopRightRadius: "30px",
      width: "245px",
      textTransform: "none",
      fontSize: "14px",
      fontWeight: 600,
    },
  },
  paginationStyle: {
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

interface Props {
  columns: string[];
  data: any[];
  addLabel: string;
  addLink?: string;
  limit: number;
  totalCount: number;
  setKeyword: (value: string) => void;
  handleAddClick?: () => void;
  onSearch: () => void;
  loading?: boolean;
}

export default function CommonList({
  columns,
  data,
  addLabel,
  limit,
  totalCount,
  setKeyword,
  onSearch,
  loading,
  addLink,
  handleAddClick,
}: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { search } = useLocation();
  const page = Number(new URLSearchParams(search).get("page")) || 1;

  const handlePagination = useCallback(
    (_, page: number) => {
      const params = new URLSearchParams(search);
      params.delete("page");
      params.append("page", page.toString());
      history.replace({
        search: params.toString(),
      });
    },
    [search]
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <div>
          <Button
            onClick={() => (addLink ? history.push(addLink) : handleAddClick())}
            variant="outlined"
            startIcon={<AddCircleOutlineTwoToneIcon />}
            className={classes.buttonCreate}
          >
            {addLabel}
          </Button>
        </div>
        <div style={{ display: "flex", flex: "0 0 65%" }}>
          <TextField
            className={classes.searchInput}
            fullWidth
            label="Поиск"
            variant="outlined"
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button
            variant="outlined"
            startIcon={<SearchTwoToneIcon />}
            className={classes.searchButton}
            onClick={() => onSearch()}
          >
            Найти в реестре
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid #D0D0D0",
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px",
          padding: "10px 30px",
          backgroundColor: "#ffffff",
        }}
      >
        {columns.map((col) => {
          return (
            <div key={col}>
              <h3>{col}</h3>
            </div>
          );
        })}
      </div>
      <div style={{ marginBottom: "1rem" }}>
        {!loading && !data.length && (
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            Нет данных
          </div>
        )}
        {!loading &&
          !!data.length &&
          data?.map((result, index) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 30px",
                border: `1px solid #F6F6F6`,
                backgroundColor: `${index % 2 == 0 ? "#F6F6F6" : "#FFFFFF"}`,
                alignItems: "center",
              }}
              key={index}
            >
              {result.render()}
            </div>
          ))}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          Показано {data.length} из {totalCount}
        </div>
        <Pagination
          className={classes.paginationStyle}
          onChange={handlePagination}
          page={page}
          count={Math.ceil(totalCount / limit) || 0}
          color="primary"
        />
      </div>
    </div>
  );
}
