import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller, Control } from "react-hook-form";
import { useCallback } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  selectMenu: {
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "250px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "30px !important",
    },

    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main}`,
    },
  },
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb2: {
    marginBottom: "1rem",
  },
}));

interface IProps {
  errors: any;
  control: Control<any, any>;
  enabled: boolean;
}

export default function PersonInterPassForm({
  errors,
  control,
  enabled,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = useCallback((value, onChange) => {
    if (!value) {
      onChange("");
      return;
    }
    if (isNaN(Number(value))) {
      if (value.length === 1) {
        onChange("");
        return;
      }
      onChange(value.slice(0, value.length - 1));
      return;
    }
    onChange(value);
  }, []);

  return (
    <div>
      <div>
        <Controller
          name="internationalPassport.lastName"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={enabled}
              disabled={!enabled}
              id="standard-required"
              label="Фамилия"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="lastName"
              margin="normal"
              ref={ref}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="internationalPassport.firstName"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={enabled}
              disabled={!enabled}
              id="standard-required"
              label="ИМЯ"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="firstName"
              margin="normal"
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              ref={ref}
            />
          )}
        />
      </div>
      <div className={classes.mb2}>
        <Controller
          name="internationalPassport.middleName"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              id="standard-required"
              label="ОТЧЕСТВО"
              disabled={!enabled}
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="middleName"
              margin="normal"
              ref={ref}
              error={!!errors?.middleName}
              helperText={errors?.middleName?.message}
            />
          )}
        />
      </div>
      <div className={classes.mb2}>
        <Controller
          name="internationalPassport.birthDay"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              id="date"
              label="ДАТА РОЖДЕНИЯ"
              type="date"
              required={enabled}
              disabled={!enabled}
              name="birthDay"
              value={value || ""}
              className={classes.textField}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors?.birthDay}
              helperText={errors?.birthDay?.message}
              ref={ref}
            />
          )}
        />
      </div>
      <div className={classes.mb2}>
        <FormControl className={classes.formControl}>
          <InputLabel id="inter-pass-gender-simple">ПОЛ *</InputLabel>
          <Controller
            name="internationalPassport.gender"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                labelId="inter-pass-gender-simple"
                id="inter-pass-gender-simple"
                label="Пол *"
                value={value || ""}
                onChange={onChange}
                required={enabled}
                disabled={!enabled}
                className={classes.selectMenu}
                inputProps={{
                  name: "inter-pass-gender",
                }}
                ref={ref}
                error={!!errors?.gender}
              >
                <MenuItem value="">Не выбрано</MenuItem>
                <MenuItem value="male">Мужчина</MenuItem>
                <MenuItem value="female">Женщина</MenuItem>
              </Select>
            )}
          />
          {errors?.gender && (
            <FormHelperText error>{errors.gender?.message}</FormHelperText>
          )}
        </FormControl>
      </div>
      <div>
        <Controller
          name="internationalPassport.nationality"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={enabled}
              disabled={!enabled}
              id="standard-required"
              label="Гражданство"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="nationality"
              margin="normal"
              ref={ref}
              error={!!errors?.nationality}
              helperText={errors?.nationality?.message}
              type="text"
              inputMode="text"
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="internationalPassport.code"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={enabled}
              disabled={!enabled}
              id="standard-required"
              label="Серия"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="code"
              margin="normal"
              ref={ref}
              error={!!errors?.code}
              helperText={errors?.code?.message}
              type="text"
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="internationalPassport.passport"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={enabled}
              disabled={!enabled}
              id="standard-required"
              label="№ паспорта"
              className={classes.textField}
              onChange={(e) => handleChange(e.target.value, onChange)}
              value={value || ""}
              name="passport"
              margin="normal"
              ref={ref}
              error={!!errors?.passport}
              helperText={errors?.passport?.message}
              type="text"
              inputMode="numeric"
            />
          )}
        />
      </div>
    </div>
  );
}
