import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import {ReactNotifications} from "react-notifications-component";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const theme = createTheme({
  palette: {
    success: {
      main: "#2BC155",
      light: "#7EEB7C",
    },
    primary: {
      main: "#419EFF",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <ReactNotifications />
          <App />
        </ThemeProvider>
      </LocalizationProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
