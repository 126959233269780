import {useEffect, useState} from "react";
import {Button, TextField, Theme} from "@mui/material";
import useFetch, {CachePolicies} from "use-http";
import {notificationTemplate} from "../../App";
import {Store} from "react-notifications-component";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import useTheme from "@mui/styles/useTheme";
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../routes/AppRoutes";

const useStyles = makeStyles((theme: Theme) => ({
    textField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "30px",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
    },
    loginContainer: {
        position: "relative",
        padding: "40px",
        borderRadius: "20px",
        boxShadow: "0px 4px 8px rgb(20 37 59 / 8%)",
        marginTop: "16px",
        width: "380px",
        height: "292px",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down('sm')]: {
            fontSize: "2em",
            width: "80%",
            padding: "20px",
            marginTop: "-25px"
        },
    },
    pageTitle: {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17.1px",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            padding: "0 20px"
        },
    },
    pageSubTitle: {
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "34px",
        lineHeight: "41px",
        textAlign: "center",
        margin: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: "2em"
        },
    },
    mb1: {
        marginBottom: "1rem",
    },
    mb2: {
        marginBottom: "2rem",
    },
    alignCenter: {
        alignItems: "center",
    },
    containerCol: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "3rem",
        maxWidth: "500px",
    },
    textCenter: {
        textAlign: "center",
    },
    loginTitleText: {
        fontFamily: "Montserrat",
        marginBottom: "32px",
        fontSize: "18px",
        textAlign: "center",
        color: "#000000E5",
    },
    button: {
        borderRadius: "30px !important",
        fontWeight: "bold !important",
        backgroundColor: `${theme.palette.primary.main} !important`,
    },
}));

export default function LoginForm() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const history = useHistory();

    const [loginData, setLoginData] = useState({
        login: "",
        password: "",
    });

    const {post: postLogin, loading, error, data: loginResponseData} = useFetch(
        process.env.REACT_APP_API,
        {
            cachePolicy: CachePolicies.NETWORK_ONLY,
            credentials: "include",
        }
    );

    useEffect(() => {
        if (!error) return;
        Store.addNotification({
            ...notificationTemplate,
            type: "danger",
            title: "Ошибка",
            message: loginResponseData?.error?.message,
        });
    }, [error, loginResponseData]);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        const data = await postLogin(`/users/login`, loginData);
        if (!data.error) {
            localStorage.setItem("token", data.authToken);
            history.push(RoutePaths.personsFind);
            window.location.reload();
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.containerCol}>
                <div className={classes.mb1}>
                    <div className={classes.textCenter}>
                        <img
                            width="80"
                            height="80"
                            src={`${process.env.PUBLIC_URL}/tajik_emblem.svg`}
                            className={classes.mb1}
                            alt="logo"
                        />
                        <p className={classes.pageTitle}>
                            Министерство здравоохранения и социальной защиты населения
                        </p>
                    </div>
                </div>
                <h2 className={classes.pageSubTitle}>
                    Республиканский центр иммунопрофилактики
                </h2>
            </div>
            <div className={classes.loginContainer}>
                <h4 className={classes.loginTitleText}>Войти в систему</h4>
                <form onSubmit={onSubmit}>
                    <div className={classes.mb1}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            fullWidth
                            value={loginData.login}
                            placeholder="Введите логин"
                            onChange={(e) =>
                                setLoginData({...loginData, login: e.target.value})
                            }
                            label="Логин"
                        />
                    </div>
                    <div className={classes.mb2}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            value={loginData.password}
                            type="password"
                            placeholder="Введите пароль"
                            onChange={(e) =>
                                setLoginData({...loginData, password: e.target.value})
                            }
                            label="Пароль"
                            autoComplete="on"
                        />
                    </div>
                    <div>
                        <Button
                            className={classes.button}
                            disabled={!loginData.login || !loginData.password || loading}
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Войти
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
