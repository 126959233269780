import { useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import CommonList from "../list/CommonList";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFetch, { CachePolicies } from "use-http";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import { Store } from "react-notifications-component";
import { notificationTemplate } from "../../App";

const columns = ["Оператор", "Организация", ""];
export default function OperatorsListPage() {
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const { search } = useLocation();
  const [keyword, setKeyword] = useState("");
  const page = Number(new URLSearchParams(search).get("page")) || 1;
  const limit = +new URLSearchParams(search).get("limit") || 20;

  const {
    data: operatorsData,
    loading: usersLoading,
    post: fetchUsers,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const {
    loading: blockLoading,
    put: putBlock,
    error,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const usersListLoad = () => {
    fetchUsers("users/find-by-filter", {
      limit,
      offset: page - 1 > 0 ? (page - 1) * limit : 0,
      keyword,
    });
  };

  useEffect(() => {
    usersListLoad();
  }, [search]);

  useEffect(() => {
    if (error) {
      Store.addNotification({
        ...notificationTemplate,
        type: "danger",
        message: `${error}`,
      });
    }
  }, [error]);

  const onSearch = useCallback(() => {
    usersListLoad();
  }, [search, keyword]);

  const handleToggleLock = (userId: number) => {
    if (blockLoading) return;
    putBlock(`users/${userId}/toggle-block`).then((response) => {
      if (!response.error) {
        usersListLoad();
      }
    });
  };

  const handleToggleReportVisible = (userId: number) => {
    if (blockLoading) return;
    putBlock(`users/${userId}/toggle-report-visible`).then((response) => {
      if (!response.error) {
        usersListLoad();
      }
    });
  };

  const data = useMemo(() => {
    if (!operatorsData || !operatorsData.list) return [];
    return operatorsData.list.map((operator) => {
      return {
        render: () => {
          return (
            <React.Fragment key={operator.id}>
              <div
                style={{ color: theme.palette.success.main, flex: "0 0 20%" }}
              >
                <h4>{operator.name}</h4>
              </div>
              <div>{operator.organization.name}</div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Link
                  style={{ color: "black", marginRight: "2rem" }}
                  to={`/operators/${operator.id}/edit`}
                >
                  <CreateSharpIcon />
                </Link>
                <span
                  style={{ cursor: "pointer", marginRight: "2rem" }}
                  onClick={() => handleToggleLock(operator.id)}
                >
                  {operator.blocked ? <LockIcon /> : <LockOpenIcon />}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleToggleReportVisible(operator.id)}
                >
                  {operator.reportVisible ? (
                    <FileDownloadIcon />
                  ) : (
                    <FileDownloadOffIcon />
                  )}
                </span>
              </div>
            </React.Fragment>
          );
        },
      };
    });
  }, [operatorsData]);

  if (operatorsData && operatorsData.error) {
    return <div>Ошибка !</div>;
  }

  return (
    <CommonList
      addLabel="Добавить оператора"
      addLink="/operators/add"
      columns={columns}
      data={data || []}
      limit={limit}
      totalCount={operatorsData?.count}
      setKeyword={setKeyword}
      onSearch={onSearch}
      loading={usersLoading}
    />
  );
}
