import { useMemo, useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonFindForm from "../../form/PersonFindForm";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  PersonDocument,
  schema as PersonDocumentSchema,
} from "../../../models/PersonDocument";
import { FamilyStatus } from "../../../enums/FamilyStatus";
import { DocumentType } from "../../../enums/DocumentType";
import { PersonGender } from "../../../enums/PersonGender";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Person, schema as PersonSchema } from "../../../models/Person";
import useFetch, { CachePolicies } from "use-http";
import useTheme from "@mui/material/styles/useTheme";
import { Theme } from "@mui/material/styles";
import CustomTable from "../../table/CustomTable";

const useStyles = makeStyles((theme: Theme) => ({
  buttonFind: {
    margin: theme.spacing(),
    backgroundColor: `${theme.palette.success.main} !important`,
    borderRadius: "30px !important",
    "&:disabled": {
      backgroundColor: `${theme.palette.grey.A400} !important`,
    },
  },
  buttonCreate: {
    margin: theme.spacing(),
    backgroundColor: `#FFFFFF !important`,
    color: `#282828 !important`,
    borderRadius: "30px !important",
    width: "130px !important",
    fontFamily: "Montserrat !important",
    fontWeight: 400,
    fontSize: "12px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#000000",
    textAlign: "start",
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb2: {
    marginBottom: "2rem",
  },
  mt2: {
    marginTop: "2rem",
  },
  notFoundMsg: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "large",
  },
}));

const columns = [
  { field: "id", headerName: "ID", width: 20 },
  {
    field: "lastName",
    headerName: "Фамилия",
    width: 150,
  },
  {
    field: "firstName",
    headerName: "Имя",
    width: 150,
  },
  {
    field: "middleName",
    headerName: "Отчество",
    width: 150,
  },
  {
    field: "birthDay",
    headerName: "Дата рождения",
    width: 150,
  },
  {
    field: "gender",
    headerName: "Пол",
    width: 150,
  },
  {
    field: "seria",
    headerName: "Серия паспорта",
    width: 150,
  },
  {
    field: "phone",
    headerName: "Телефон",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Статус",
    width: 200,
    renderCell: (params) => {
      const text = params.row.status ? "Вакцинирован" : "Вакц. не полностью";
      return (
        <div
          style={{
            height: "10px",
            width: "10px",
            backgroundColor: params.row.status ? "#2BC155" : "#419EFF",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginLeft: "1rem" }}>{text}</span>
        </div>
      );
    },
  },
];

const limit = 50;

export default function CertificatePersonFindPage() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const token = localStorage.getItem("token");

  const {
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        person: PersonSchema,
        document: PersonDocumentSchema,
      })
    ),
    defaultValues: {
      person: Person.build({
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        inn: "",
        nationalNumber: "",
        birthDay: null,
        gender: "" as PersonGender,
      }),
      document: PersonDocument.build({
        id: null,
        documentType: "" as DocumentType.Passport,
        seria: "",
        number: "",
        familyStatus: "" as FamilyStatus,
      }),
    },
  });

  const [findClicked, setFindClicked] = useState(false);
  const isValid: boolean =
    watch("person.lastName") !== "" ||
    watch("person.firstName") !== "" ||
    watch("document.seria") !== "" ||
    watch("document.number") !== "";

  const {
    post: fetchPersonsByFilter,
    data: personsData,
    loading: personsLoading,
  } = useFetch(process.env.REACT_APP_API, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  const onFindUser = () => {
    setFindClicked(true);
    fetchPersonsByFilter("persons/get-by-filter", {
      limit,
      offset: 0,
      ...watch("person"),
      ...watch("document"),
    });
  };

  const translateGender = (gender: PersonGender) => {
    if (!gender) return "";
    if (gender === PersonGender.Female) {
      return "Жен.";
    } else {
      return "Муж.";
    }
  };

  const persons = useMemo(() => {
    if (!personsData || personsData.error) return [];
    return personsData.list.map((person, index) => {
      return {
        index,
        id: person.id,
        phone: person.address.phone,
        lastName: person.lastName,
        firstName: person.firstName,
        middleName: person.middleName,
        birthDay: person.birthDay,
        gender: translateGender(person.gender),
        seria: person.document.seria + person.document.number,
        status: person.fullVaccinated,
      };
    });
  }, [personsData]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginBottom: "2rem",
            padding: "1rem 2rem 2rem",
            marginTop: "-2rem",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
        >
          <PersonFindForm control={control} errors={errors}>
            <div style={{ marginTop: "0.5rem" }}>
              <Button
                onClick={onFindUser}
                variant="contained"
                disabled={personsLoading || !isValid}
                className={`${classes.buttonFind}`}
                fullWidth
              >
                Найти
              </Button>
            </div>
          </PersonFindForm>
        </div>

        {findClicked && !personsData?.list.length && (
          <p className={classes.notFoundMsg}>Субъект не существует в базе</p>
        )}
        <div style={{ flex: 1, marginLeft: "2rem" }} className={classes.mb2}>
          {personsData && personsData.list.length && (
            <CustomTable
              columns={columns}
              filterEnable={false}
              hideFooter={true}
              rows={persons}
              limit={limit}
              totalCount={personsData?.count}
              loading={personsLoading}
              page={1}
              handleRowSelect={(data) =>
                history.push(`/certificates/person/${data.id}`)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
