import PersonInfoForm from "../form/PersonInfoForm";
import PersonDocumentForm from "../form/PersonDocumentForm";

interface Props {
  disabled?: boolean;
  children?: any;
  control: any;
  errors: any;
}

export default function PersonFindForm({
  disabled,
  children,
  control,
  errors,
}: Props) {
  return (
    <div>
      <PersonInfoForm
        disabled={disabled}
        control={control}
        errors={errors.person}
      />
      <PersonDocumentForm
        disabled={disabled}
        control={control}
        errors={errors.document}
      />
      {children}
    </div>
  );
}
