import { PersonGender } from "../enums/PersonGender";
import * as yup from "yup";
import moment from "moment";
const nameRegex = /^[A-Za-z]+$/;

export const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  middleName: yup.string().optional(),
  nationality: yup.string().required(),
  gender: yup
    .string()
    .oneOf([PersonGender.Male, PersonGender.Female, ""])
    .required(),
  birthDay: yup.date()
      .min(moment().subtract(110, 'years'), "Поле должно быть позже 27-07-1912")
      .max(moment().format("YYYY-MM-DD"), 'Нельзя выбрать будущую дату')
      .required(),
  code: yup
    .string()
    .matches(nameRegex, "Введите серию паспорта латинскими символами")
    .required(),
  passport: yup.string().required(),
});

export type BuildPersonInterPassportType = Omit<
  PersonInternationalPassport,
  "createdDate" | "updatedDate" | "name"
>;

export class PersonInternationalPassport {
  id: number;
  createdDate?: Date;
  updatedDate?: Date;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  nationality: string;
  code: number;
  passport: number;
  birthDay: Date;
  gender: PersonGender;

  static build({
    id,
    firstName,
    middleName,
    lastName,
    name,
    code,
    nationality,
    passport,
    birthDay,
    gender,
  }: Partial<PersonInternationalPassport>): BuildPersonInterPassportType {
    return Object.assign(new PersonInternationalPassport(), {
      id,
      firstName,
      middleName,
      lastName,
      name,
      code,
      passport,
      nationality,
      birthDay,
      gender,
    });
  }
}
