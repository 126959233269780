import { PersonVaccine } from "./PersonVaccine";
import { CancelReason } from "./CancelReason";
import { PersonAddress } from "./PersonAddress";
import { PersonDocument } from "./PersonDocument";
import { PersonGender } from "../enums/PersonGender";
import * as yup from "yup";
import { PersonInternationalPassport } from "./PersonInternationalPassport";
import moment from "moment";

export const schema = yup.object().shape({
  firstName: yup.string()
      .trim()
      .matches(/^[А-ЯҒӮҚҶӢҲЁ][а-яғӯқҷӣҳё]+$/, "Ошибка, поле не должен содержать цифры, символы или латинские буквы и первая буква должна быть заглавная")
      .min(2, "Поле Имя должен содержать не менее 2 букв, без символов и пробела")
      .required(),
  lastName: yup.string()
      .trim()
      .matches(/^[А-ЯҒӮҚҶӢҲЁ][а-яғӯқҷӣҳё]+$/, "Ошибка, поле не должен содержать цифры, символы или латинские буквы и первая буква должна быть заглавная")
      .min(2, "Поле Фамилия должен содержать не менее 2 букв.")
      .required(),
  middleName: yup.string().optional(),
  inn: yup.string().notRequired(),
  nationalNumber: yup.string().notRequired(),
  gender: yup
    .string()
    .oneOf([PersonGender.Male, PersonGender.Female, ""])
    .required(),
  birthDay: yup.date()
      .min(moment().subtract(110, 'years'), "Ошибка")
      .max(moment().format("YYYY-MM-DD"), 'Нельзя выбрать будущую дату')
      .required(),
});

export type BuildPersonType = Omit<
  Person,
  | "createdDate"
  | "updatedDate"
  | "name"
  | "vaccines"
  | "cancelReason"
  | "address"
  | "document"
  | "vaccineCancelled"
>;

export class Person {
  id: number;
  createdDate?: Date;
  updatedDate?: Date;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  inn: string;
  nationalNumber: string;
  birthDay: Date;
  gender: PersonGender;
  vaccines?: PersonVaccine[];
  cancelReason?: CancelReason;
  address?: PersonAddress;
  document?: PersonDocument;
  vaccineCancelled: boolean;
  internationalPassport: PersonInternationalPassport;

  static build({
    id,
    firstName,
    middleName,
    lastName,
    name,
    inn,
    nationalNumber,
    birthDay,
    gender,
  }: Partial<Person>): BuildPersonType {
    return Object.assign(new Person(), {
      id,
      firstName,
      middleName,
      lastName,
      name,
      inn,
      nationalNumber,
      birthDay,
      gender,
    });
  }
}
