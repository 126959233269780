import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    "&.MuiDataGrid-row.Mui-odd": {
      backgroundColor: "red !important",
    },
  },
  block: {
    height: "100%",
    width: "100%",
    display: "flex",
    gridGap: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      height: "70vh",
      display: "block",
      margin: "0 1rem",
    }
  }
}));

interface Props {
  columns: GridColumns;
  rows: unknown[];
  limit: number;
  totalCount: number;
  loading: boolean;
  page: number;
  handleRowSelect: (data: any) => void;
  filterEnable: boolean;
  hideFooter?: boolean;
  children?: any;
}

export default function CustomTable({
  columns,
  rows,
  limit,
  page,
  loading,
  totalCount,
  handleRowSelect,
  filterEnable,
  children,
  hideFooter,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const onPageChange = (newPage) => {
    const params = new URLSearchParams(location.search);
    params.delete("page");
    params.append("page", newPage || 1);
    history.replace({
      search: params.toString(),
    });
  };

  return (
    <div className={`${classes.block} ${filterEnable && "flex"}`}>
      {filterEnable && (
        <div
          style={{
            backgroundColor: "white",
            width: "270px",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginBottom: "2rem",
          }}
        >
          {children}
        </div>
      )}
      <DataGrid
        className={`${classes.table}`}
        paginationMode="server"
        rows={rows}
        columns={columns}
        pageSize={limit}
        rowsPerPageOptions={[limit]}
        rowCount={Math.ceil(totalCount / limit)}
        loading={loading}
        page={totalCount === rows.length ? 0 : page}
        onRowClick={(row) => {
          handleRowSelect(row.row);
        }}
        hideFooterPagination={totalCount === rows.length || hideFooter}
        onPageChange={(newPage) => onPageChange(newPage)}
      />
    </div>
  );
}
