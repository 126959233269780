import { Checkbox, Theme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles, useTheme } from "@mui/styles";
import { CancelReason } from "../../models/CancelReason";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb2: {
    marginBottom: "1rem",
  },
  reason: {
    color: `black`,
    fontWeight: "bold",
    fontFamily: "Montserrat&display=swap",
    fontSize: "14px",
  }
}));

interface IProps {
  cancelReason: Partial<CancelReason>;
  onChange: (cancelReason: Partial<CancelReason>) => void;
  disabled?: boolean;
}

const reasons = [
  {
    name: "Гиперчувствительность к препарату",
    value: "hypersensitivity",
  },
  {
    name: "Беременность и кормление грудью младенца",
    value: "pregnancy",
  },
  {
    name: "Лица с пониженным иммунитетом",
    value: "lowImmunity",
  },
  {
    name: "Аутоимунное заболевание",
    value: "autoimmune",
  },
  {
    name: "Возраст 65 лет и старше",
    value: "oldAge",
  },
  {
    name: "Дети до 18 лет",
    value: "teenager",
  },
  {
    name: "Тяжелыми форма аллергических заболеваний",
    value: "allergic",
  },
  {
    name: "Другое",
    value: "other",
  },
];

export default function PersonCancelReasonForm({
  cancelReason,
  onChange,
  disabled = false,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr)", gridGap: "10px"}}>
        {reasons.map((reason) => {
          return (
            <div
              style={{ cursor: "pointer"}}
              onClick={() => {
                if (disabled) return;
                onChange({
                  ...cancelReason,
                  [reason.value]: !cancelReason[reason.value],
                });
              }}
              key={reason.value}
              className={classes.flexItems}
            >
              <Checkbox
                disabled={disabled}
                checked={cancelReason[reason.value] || false}
                value={cancelReason[reason.value] || ""}
                color="primary"
              />
              <span className={classes.reason}>{reason.name}</span>
            </div>
          );
        })}
        {cancelReason.other && (
          <TextField
            required
            id="standard-required"
            label="Причина отказа"
            className={classes.textField}
            onChange={(e) => {
              if (disabled) return;
              onChange({
                ...cancelReason,
                otherText: e.target.value,
              });
            }}
            value={cancelReason.otherText}
            name="otherText"
            margin="normal"
            error={!cancelReason.otherText && cancelReason.other}
            helperText={
              !cancelReason.otherText &&
              cancelReason.other &&
              "Укажите причину отказа"
            }
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
}
