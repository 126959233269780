import { Person } from "./Person";
import { Vaccine } from "./Vaccine";
import { User } from "./User";
import * as yup from "yup";
import moment from "moment";

export const schema = yup.object().shape({
  seria: yup.string(),
  expireDate: yup.date(),
  vaccinationDate: yup.date()
      .nullable()
      .min(moment("2020-01-01").toISOString(), "Ошибка")
      .max(moment().format("YYYY-MM-DD"), 'Нельзя выбрать будущую дату')
      .required(),
  vaccineId: yup.number().positive().integer().required(),
  vaccineSeriaId: yup.number().when("seria", {
    is: (value) => !value,
    then: yup.number().positive().min(1).required(),
  }),
});

export type BuildPersonVaccineType = Omit<
  PersonVaccine,
  | "createdDate"
  | "updatedDate"
  | "personId"
  | "person"
  | "userId"
  | "user"
  | "vaccine"
  | "vaccineId"
>;
export class PersonVaccine {
  id: number;
  createdDate: Date;
  updatedDate: Date;
  seria: string;
  expireDate: Date;
  vaccinationDate: Date;
  doseNumber: number;
  personId: number;
  vaccineId: number;
  vaccineSeriaId: number;
  userId: number;
  person: Person;
  user: User;
  vaccine: Vaccine;

  static build({
    id,
    expireDate,
    vaccineId,
    vaccineSeriaId,
    vaccinationDate,
    seria,
    doseNumber,
  }: Partial<PersonVaccine>): BuildPersonVaccineType {
    return Object.assign(new PersonVaccine(), {
      id,
      expireDate,
      vaccineId,
      vaccineSeriaId,
      vaccinationDate,
      doseNumber,
      seria,
    });
  }
}
