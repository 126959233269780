import PersonListPage from "../components/page/PersonListPage";
import PersonViewPage from "../components/page/PersonViewPage";
import PersonFindPage from "../components/page/PersonFindPage";
import OperatorsListPage from "../components/page/OperatorsListPage";
import OrganizationListPage from "../components/page/OrganizationListPage";
import OperatorAddEditPage from "../components/page/OperatorAddEditPage";
import OrganizationAddEditPage from "../components/page/OrganizationAddEditPage";
import AdminDashboardPage from "../components/page/admin/admin-dashboard-view/AdminDashboardPage";
import CertificatePersonFindPage from "../components/page/certificate-person-find-page/CertificatePersonFindPage";
import CertificatePersonViewPage from "../components/page/certificate-person-view-page/CertificatePersonViewPage";
import VaccineListPage from "../components/page/vaccine-list-page/VaccineListPage";
import VaccineViewPage from "../components/page/vaccine-view-page/VaccineViewPage";
import {UserRole} from "../enums/UserRole";

export enum RoutePaths {
  mainRoute = "/",
  personsFind = "/persons/find",
  personsAdd = "/persons/add",
  personsEdit = "/persons/:id/edit",
  operators = "/operators",
  operatorEdit = "/operators/:id/edit",
  operatorAdd = "/operators/add",
  organizations = "/organizations",
  organizationAdd = "/organizations/add",
  organizationEdit = "/organizations/:id/edit",
  adminDashboard = "/dashboard",
  certificates = "/certificates",
  certificatesPerson = "/certificates/person/:id",
  vaccinesListPage = "/vaccines",
  vaccineViewPage = "/vaccines/:id/series",
  educationalMaterials = "/educational-materials",
  techSupport = '/tech-support',
}

type Route = {
  path: string;
  component: any;
  name: string;
  icon?: string;
  main: boolean;
  roles: UserRole[];
  children?: Route[];
};

export const appRoutes: Route[] = [
  {
    path: RoutePaths.mainRoute,
    component: PersonListPage,
    name: "Главная",
    main: true,
    roles: [UserRole.Admin, UserRole.CentralOffice],
    icon: "menu_main.svg",
  },
  {
    path: RoutePaths.personsFind,
    component: PersonFindPage,
    name: "Поиск гражданина",
    main: true,
    roles: [UserRole.Admin, UserRole.Operator, UserRole.CentralOffice],
    icon: "search_and_register.svg",
  },
  {
    path: RoutePaths.personsAdd,
    component: PersonViewPage,
    name: "",
    main: false,
    roles: [UserRole.Admin, UserRole.Operator, UserRole.CentralOffice],
  },
  {
    path: RoutePaths.personsEdit,
    component: PersonViewPage,
    name: "",
    main: false,
    roles: [UserRole.Admin, UserRole.Operator, UserRole.CentralOffice],
  },
  {
    path: RoutePaths.operators,
    component: OperatorsListPage,
    name: "Операторы",
    main: true,
    roles: [UserRole.Admin],
    icon: "operators_menu_icon.svg",
  },
  {
    path: RoutePaths.organizations,
    component: OrganizationListPage,
    name: "Организации",
    main: true,
    roles: [UserRole.Admin],
    icon: "organizations_menu_icon.svg",
  },
  {
    path: RoutePaths.operatorAdd,
    component: OperatorAddEditPage,
    name: "Добавить оператора",
    main: false,
    roles: [UserRole.Admin],
  },
  {
    path: RoutePaths.operatorEdit,
    component: OperatorAddEditPage,
    name: "Редактировать оператора",
    main: false,
    roles: [UserRole.Admin],
  },
  {
    path: RoutePaths.organizationAdd,
    component: OrganizationAddEditPage,
    name: "Добавить организацию",
    main: false,
    roles: [UserRole.Admin],
  },
  {
    path: RoutePaths.organizationEdit,
    component: OrganizationAddEditPage,
    name: "Редактировать организацию",
    main: false,
    roles: [UserRole.Admin],
  },
  {
    path: RoutePaths.adminDashboard,
    component: AdminDashboardPage,
    name: "Отчёты",
    main: true,
    roles: [UserRole.Admin, UserRole.CentralOffice],
    icon: "report_menu_icon.svg",
  },
  {
    path: RoutePaths.certificates,
    component: CertificatePersonFindPage,
    name: "Сертификаты",
    main: true,
    roles: [UserRole.Admin, UserRole.Operator, UserRole.CentralOffice],
    icon: "certificate_menu_icon.svg",
  },
  {
    path: RoutePaths.certificatesPerson,
    component: CertificatePersonViewPage,
    name: "Просмотр данных",
    main: false,
    roles: [UserRole.Admin, UserRole.Operator],
  },
  {
    path: RoutePaths.vaccinesListPage,
    component: VaccineListPage,
    name: "Вакцины",
    main: true,
    roles: [UserRole.Admin],
    icon: "vaccine_menu_icon.svg",
  },
  {
    path: RoutePaths.vaccineViewPage,
    component: VaccineViewPage,
    name: "Вакцина",
    main: false,
    roles: [UserRole.Admin],
  },
];
