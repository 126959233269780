import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useState} from "react";
import {AutoFixHighOutlined} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";
import {UserContext} from "../../../App";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        boxStyles: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            height: '70vh',
        },
        pdfView: {
            width: '42vw',
            height: '65vh',
        }
    }
});



const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const tabPanelItems = [
    {id: 0, src: "/manuals/manual_admin_1.pdf#toolbar=0", role: "admin"},
    {id: 1, src: "/manuals/manual_admin_2.pdf#toolbar=0", role: "admin"},
    {id: 2, src: "/manuals/manual_operator.pdf#toolbar=0", role: "operator"},
]

const tavItems = [
    {id: 0, label: "Руководство к использованию ИС ЁРИ (Администратор)", role: "admin"},
    {id: 1, label: "Руководство к использованию ИС ЁРИ (Администратор - 2)", role: "admin"},
    {id: 2, label: "Руководство к использованию ИС ЭРИ (Оператор)", role: "operator"},
]


export default function VerticalTabs() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {role} = useContext(UserContext);
    const initialValue = role === "operator" ? 0 : tabPanelItems.find(el => el.role === role)?.id;
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box className={classes.boxStyles}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {tavItems.filter(item => item.role === role).map((el) => (
                    <Tab
                        iconPosition="start"
                        label={el.label}
                        icon={<AutoFixHighOutlined/>}
                        style={{textAlign: 'left'}}
                        {...a11yProps(value)}
                    />
                ))}
            </Tabs>

            {tabPanelItems.filter((item) => item.role === role && (role === "operator" ? !0 : item.id === value)).map((el) => (
                <TabPanel value={value} index={value}>
                    <object data={`${process.env.PUBLIC_URL}${el.src}`} type="application/pdf" className={classes.pdfView}>
                        <p>Alternative text - include a link <a href={`${process.env.PUBLIC_URL}${el.src}`}>to the PDF!</a></p>
                    </object>
                </TabPanel>
            ))}
        </Box>
    );
}
