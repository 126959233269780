import * as yup from "yup";

export const schema = yup.object().shape({
  position: yup.string().notRequired(),
  placeOfWork: yup.string().notRequired(),
  occupation: yup.number().required(),
});

export type BuildPersonJobType = Omit<
  PersonJob,
  "createdDate" | "updatedDate" | "personId" | "person"
>;
export class PersonJob {
  id: number;
  createdDate?: Date;
  updatedDate?: Date;
  occupation: boolean | number;
  position: string;
  placeOfWork: string;
  personId: number;

  static build({
    id,
    occupation,
    position,
    placeOfWork,
  }: Partial<PersonJob>): BuildPersonJobType {
    return Object.assign(new PersonJob(), {
      id,
      occupation,
      position,
      placeOfWork,
    });
  }
}
