import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
  },
  edit: {
    cursor: "pointer",
  },
  ml1: {
    marginLeft: "1rem",
  },
  mb2: {
    marginBottom: "2rem",
  },
  noData: {
    textAlign: "center",
  },
}));
