import {useCallback, useEffect, useMemo, useState} from "react";
import CustomTable from "../table/CustomTable";
import {useHistory, useLocation} from "react-router-dom";
import {PersonGender} from "../../enums/PersonGender";
import TextField from "@mui/material/TextField";
import {makeStyles, useTheme} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import DateRangePicker, {DateRange} from "@mui/lab/DateRangePicker";
import moment, {Moment} from "moment";
import RegionRegistry from "../../utils/RegionRegistry";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import useFetch, {CachePolicies} from "use-http";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#000000",
        textAlign: "start",
    },
    applyButton: {
        "&.MuiButtonBase-root.MuiButton-root": {
            color: "black",
            textTransform: "none",
            fontWeight: "bold",
            borderRadius: "30px",
            borderColor: "#D0D0D0",
            fontSize: "14px",
            width: "100%",
            fontFamily: "inherit",
        },
    },
    dateRange: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
        },
        "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
            color: `${theme.palette.primary.main} !important`,
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },
    checkbox: {
        "&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked": {
            color: theme.palette.primary.main,
        },
    },
    mt1: {
        marginTop: "1rem",
    },
    mb1: {
        marginBottom: "1rem !important",
    },
}));

const columns = [
    {field: "id", headerName: "ID", width: 30},
    {
        field: "lastName",
        headerName: "Фамилия",
        width: 150,
    },
    {
        field: "firstName",
        headerName: "Имя",
        width: 150,
    },
    {
        field: "middleName",
        headerName: "Отчество",
        width: 150,
    },
    {
        field: "birthDay",
        headerName: "Дата рождения",
        width: 150,
    },
    {
        field: "gender",
        headerName: "Пол",
        width: 150,
    },
    {
        field: "seria",
        headerName: "Серия паспорта",
        width: 150,
    },
    {
        field: "phone",
        headerName: "Телефон",
        width: 150,
        editable: true,
    },
    {
        field: "status",
        headerName: "Статус",
        width: 200,
        renderCell: (params) => {
            const text = params.row.status ? "Вакцинирован" : "Вакц. не полностью";
            return (
                <div
                    style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: params.row.status ? "#2BC155" : "#419EFF",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <span style={{marginLeft: "1rem"}}>{text}</span>
                </div>
            );
        },
    },
];

const limit = 50;
export default function PersonListPage() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const token = localStorage.getItem("token");
    const [dateRange, setDateRange] = useState<DateRange<Moment>>([null, null]);
    const [regions, setRegions] = useState([]);
    const {search} = useLocation();
    const history = useHistory();
    const page = Number(new URLSearchParams(search).get("page")) || 1;
    const [districts, setDistricts] = useState<Array<string>>([])

    const {
        post: fetchPersonsByFilter,
        data: personsData,
        loading,
    } = useFetch(process.env.REACT_APP_API, {
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
        },
        cachePolicy: CachePolicies.NETWORK_ONLY,
    });

    useEffect(() => {
        const params = new URLSearchParams(search);
        const dateFrom = params.get("dateFrom") || "";
        const dateTo = params.get("dateTo") || "";
        const districts = params.get("districts")?.split(',') || [];
        const page = Number(params.get("page")) || 1;
        const regions = params.get("regions")?.split(",") || [];
        const payload = {
            limit: String(limit),
            offset: String(page - 1 > 0 ? (page - 1) * limit : 0),
            dateFrom,
            dateTo,
            regions,
            districts
        }
        const queryParamas = new URLSearchParams(payload as any).toString()
       
        const dates = [];
        if (dateFrom) {
            dates.push(moment(dateFrom));
        }
        if (dateTo) {
            dates.push(moment(dateTo));
        }
        setDateRange([dates[0] || null, dates[1] || null]);
        setRegions(regions);
        setDistricts(districts);
        fetchPersonsByFilter("persons/get-by-filter", {
            limit,
            offset: page - 1 > 0 ? (page - 1) * limit : 0,
            dateFrom,
            dateTo,
            regions,
        });
    }, [search]);

    const translateGender = (gender: PersonGender) => {
        if (!gender) return "";
        if (gender === PersonGender.Female) {
            return "Жен.";
        } else {
            return "Муж.";
        }
    };

    const toggleRegion = useCallback(
        (region: string) => {
            const regionMatchIndex = regions.findIndex((result) => result === region);
            if (regionMatchIndex >= 0) {
                setRegions(regions.filter((_, index) => index !== regionMatchIndex));
            } else {
                setRegions(regions.concat(region));
            }
        },
        [regions]
    );

    const toggleDistrict = useCallback(
        (district: string) => {
            const regionMatchIndex = districts.findIndex((result) => result === district);
            if (regionMatchIndex >= 0) {
                setDistricts(districts.filter((_, index) => index !== regionMatchIndex));
            } else {
                setDistricts(districts.concat(district));
            }
        }, [districts]);

    const handleSubmit = useCallback(() => {
        const params = new URLSearchParams();
        const dateFrom = dateRange[0];
        const dateTo = dateRange[1];
        const page = Number(new URLSearchParams(search).get("page")) || 0;

        if (dateFrom) {
            params.append("dateFrom", dateFrom.format("YYYY-MM-DD"));
        }
        if (dateTo) {
            params.append("dateTo", dateTo.format("YYYY-MM-DD"));
        }
        if (regions.length) {
            params.append("regions", regions.toString());
        }
        if (page) {
            params.append("page", page.toString());
        }
        if (districts.length) {
            params.append("districts", districts.toString())
        }

        history.push({search: params.toString()});
    }, [dateRange, regions, districts]);

    const persons = useMemo(() => {
        if (!personsData || personsData.error) return [];
        return personsData?.list?.map((person, index) => {
            return {
                index,
                id: person.id,
                phone: person.address.phone,
                lastName: person.lastName,
                firstName: person.firstName,
                middleName: person.middleName,
                birthDay: person.birthDay,
                gender: translateGender(person.gender),
                seria: person.document.seria + person.document.number,
                status: person.fullVaccinated,
            };
        });
    }, [personsData]);

    if (!personsData) return null;

    return (
        <div style={{height: "800px",}}>
            <CustomTable
                columns={columns}
                rows={persons || []}
                limit={limit}
                totalCount={personsData?.count}
                loading={loading}
                page={page}
                filterEnable={true}
                handleRowSelect={(data) => history.push(`/persons/${data.id}/edit`)}
            >
                <div>
                    <h4 className={classes.title}>Период вакцинации</h4>
                    <DateRangePicker
                        startText="От"
                        endText="До"
                        value={dateRange}
                        onChange={(newValue) => {
                            setDateRange(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField
                                    className={classes.dateRange}
                                    {...startProps}
                                    size="small"
                                />
                                <TextField
                                    className={classes.dateRange}
                                    {...endProps}
                                    size="small"
                                />
                            </>
                        )}
                    />
                    <h4 className={classes.title}>Область</h4>
                    <div>
                        {RegionRegistry.getList().map((region) => {
                            const regionMatch = regions.find(
                                (result) => result === region.region.value
                            );

                            return (
                                <div
                                    style={{cursor: "pointer"}}
                                    onClick={() => toggleRegion(region.region.value)}
                                    key={region.region.value}
                                >
                                    <Checkbox
                                        className={classes.checkbox}
                                        id={region.region.value}
                                        checked={!!regionMatch}
                                        value={region.region.value || ""}
                                        color="primary"
                                    />
                                    <span>{region.region.name}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <h4 className={classes.title}>Город / Район</h4>
                        <ul style={{height: '200px', overflow: 'scroll'}}>
                            {RegionRegistry.filterDistrictsByRegion(regions).map(district => {
                                const districtMatch = districts.find(
                                    (result) => result === district.value
                                );
                                return (
                                    <div
                                        style={{cursor: "pointer"}}
                                        onClick={() => toggleDistrict(district.value)}
                                        key={district.value}
                                    >
                                        <Checkbox
                                            className={classes.checkbox}
                                            id={district.value}
                                            checked={!!districtMatch}
                                            value={district.value || ""}
                                            color="primary"
                                        />
                                        <span>{district.name}</span>
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                    <div className={classes.mt1}>
                        <Button
                            onClick={() => handleSubmit()}
                            className={`${classes.applyButton} ${classes.mb1}`}
                            variant="outlined"
                        >
                            Применить фильтр
                        </Button>
                        <Button
                            onClick={() => history.push({search: `page=${String(page)}`})}
                            className={classes.applyButton}
                            variant="outlined"
                        >
                            Сбросить фильтр
                        </Button>
                    </div>
                </div>
            </CustomTable>
        </div>
    );
}
