import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CommonList from "../list/CommonList";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import useFetch, { CachePolicies } from "use-http";
import { useTheme } from "@mui/material";
import RegionRegistry from "../../utils/RegionRegistry";

const columns = ["Организация", "Город", "Район", "Адрес"];
const limit = 10;

export default function OperatorsListPage() {
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const { search } = useLocation();
  const [keyword, setKeyword] = useState("");
  const page = Number(new URLSearchParams(search).get("page")) || 1;

  const {
    data: organizationsData,
    loading: organizationsLoading,
    post: fetchOrganizations,
  } = useFetch(`${process.env.REACT_APP_API}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  useEffect(() => {
    fetchOrganizations("organizations/find-by-filter", {
      limit,
      offset: page - 1 > 0 ? (page - 1) * limit : 0,
    });
  }, [search]);

  const onSearch = useCallback(() => {
    fetchOrganizations("organizations/find-by-filter", {
      limit,
      offset: page - 1 > 0 ? (page - 1) * limit : 0,
      keyword,
    });
  }, [search, keyword]);

  const data = useMemo(() => {
    if (!organizationsData || !organizationsData.list) return [];
    return organizationsData.list.map((organization) => {
      const region = RegionRegistry.getList().find(
        (result) => result.region.value === organization.region
      );
      const district = RegionRegistry.getDistrictsByRegion(
        organization?.region
      )?.find((district) => district.value === organization.district);

      return {
        render: () => {
          return (
            <React.Fragment key={organization.id}>
              <div
                style={{ color: theme.palette.success.main, flex: "0 0 20%" }}
              >
                <h4>{organization.name}</h4>
              </div>
              <div>{region?.region.name}</div>
              <div>{district?.name}</div>
              <div>{organization.address}</div>
              <div>
                <Link
                  style={{ color: "black" }}
                  to={`/organizations/${organization.id}/edit`}
                >
                  <CreateSharpIcon />
                </Link>
              </div>
            </React.Fragment>
          );
        },
      };
    });
  }, [organizationsData]);

  if (organizationsData && organizationsData.error) {
    return <div>Error !</div>;
  }

  return (
    <CommonList
      addLabel="Добавить организацию"
      addLink="/organizations/add"
      columns={columns}
      data={data || []}
      limit={limit}
      totalCount={organizationsData?.count}
      setKeyword={setKeyword}
      onSearch={onSearch}
      loading={organizationsLoading}
    />
  );
}
