import React, {FC} from 'react';
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = makeStyles((theme: Theme) => {
    return {
        header: {
            display: "grid",
            gridTemplateColumns: "50px 1fr 120px 120px",
            minHeight: "50px",
            placeContent: "center",
            listStyleType: "none",
            padding: "5px 10px",
            backgroundColor: "rgba(207, 243, 219, 0.52)",
            fontWeight: "600",
            marginTop: "30px"
        },
        table: {
            display: "grid",
            gridTemplateColumns: "50px 1fr 120px 120px",
            minHeight: "50px",
            padding: "5px 10px",
            listStyleType: "none",
            placeContent: "center",
            borderBottom: "1px solid #baedce",
            "&:hover": {
                background: "rgba(95,226,146,0.2)",
            }
        },
    }
});

type Contact = {
    id: number;
    fullName: string;
    location: string;
    phoneNumber: string;
}

const header = ["№", "ФИО", "Локация", "Номер телефона"];

const contacts:Contact[] = [
    {id: 1, fullName: "Ғафуров Саидшоҳ", location: "ш.Душанбе", phoneNumber: '915449948'},
    {id: 2, fullName: "Искандарова Нигина", location: "НТЧ", phoneNumber: '935300370'},
    {id: 3, fullName: "Хучаков Тоирчон Абсаматович", location: "ш.Бохтар", phoneNumber: '935081049'},
    {id: 4, fullName: "Ахмадчонов Баходур", location: "ш.Бохтар", phoneNumber: '918653249'},
    {id: 5, fullName: "Наимов Искандар", location: "ш.Кулоб", phoneNumber: '907772757'},
    {id: 6, fullName: "Хасанов Боймухаммад", location: "Вилояти Сугд", phoneNumber: '935521116'},
    {id: 7, fullName: "Хамдамов Мирзохамдам", location: "Вилояти Сугд", phoneNumber: '929533344'},
    {id: 8, fullName: "Мирсолиева Гулнора ", location: "Вилояти Сугд", phoneNumber: '926055053'},
    {id: 9, fullName: "Фидомухаммад", location: "Минтакаи Рашт", phoneNumber: '933400606'},
]

const TechSupport:FC = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div>
            <Typography variant='h4' align='center'>Техническая поддержка</Typography>

            <main>
                <header>
                    <ul className={classes.header} >
                        {header.map(el => <li key={el}>{el}</li>)}
                    </ul>
                </header>

                <footer style={{height: "500px", overflow: "auto"}} >
                    {contacts.map(el => <ul className={classes.table} key={el.id}>
                        <li>{el.id}</li>
                        <li>{el.fullName}</li>
                        <li>{el.location}</li>
                        <li>{el.phoneNumber}</li>
                    </ul>)}
                </footer>
            </main>
        </div>
    );
};

export default TechSupport;