import {useMemo} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {makeStyles} from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import {Theme} from "@mui/material/styles";
import {appRoutes, RoutePaths} from "../../../routes/AppRoutes";
import {User} from "../../../models/User";

const drawerWidth = 340;

const useStyles = makeStyles((theme: Theme) => {
    return {
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiPaper-root-MuiDrawer-paper": {
                backgroundColor: "#FFFFFF !important",
            },
        },
        drawerPaper: {
            width: drawerWidth,
            overflow: "hidden",
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            //   padding: theme.spacing(0, 1),
            paddingBottom: "2rem",
            paddingTop: "2rem",
            ...theme.mixins.toolbar,
            justifyContent: "flex-end",
            height: "90px",
        },
        drawerTitleText: {
            color: theme.palette.success.main,
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17px",
            textAlign: "center",
        },
        ml2: {
            marginLeft: "2rem !important",
        },
        ml3: {
            marginLeft: "3rem !important",
        },
        mb1: {
            marginBottom: "0.2rem !important",
        },
        menuItemText: {
            fontFamily: "Montserrat:wght@100&display=swap",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#282828",
            textDecoration: "none",
        },
        textGray: {
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#969BA0",
            textAlign: "center",
            marginTop: "2rem",
            position: "fixed",
            bottom: "1rem",
            width: drawerWidth,
        },
    };
});

interface Props {
    currentUser: User;
    toggleDrawer: () => void;
    doLogout: () => void;
    open: boolean;
}

function DrawerComponent({currentUser, toggleDrawer, doLogout, open}: Props) {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles(theme);
    const location = useLocation()

    const menuItems = useMemo(() => {
        if (!currentUser) return [];
        return appRoutes
            .filter((route) => {
                if (!route.main) return false;
                if (!route.roles.includes(currentUser.role)) return false;
                // if (route.path === RoutePaths.personsFind && !covidOpen) return false;
                return true;
            })
            .map((route, index) => {
                return (
                    <ListItem
                        onClick={() => history.push(route.path)}
                        className={`${classes.ml2}`}
                        style={{backgroundColor: route.path === location.pathname ? 'rgb(207 243 219 / 52%)' : '', marginBottom: index === 6 ? '40%' : '0.2rem'}}
                        key={route.path}
                        button
                    >
                        <ListItemIcon>
                            <img
                                width="24"
                                height="24"
                                src={`${process.env.PUBLIC_URL}/${route.icon}`}
                            />
                        </ListItemIcon>
                        <a className={classes.menuItemText}>{route.name}</a>
                    </ListItem>
                );
            });
    }, [currentUser, location]);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <p className={classes.drawerTitleText}>
                    Республиканский центр иммунопрофилактики
                </p>
                <IconButton onClick={() => toggleDrawer()}>
                    {theme.direction === "ltr" ? (
                        <ChevronLeftIcon/>
                    ) : (
                        <ChevronRightIcon/>
                    )}
                </IconButton>
            </div>
            <Divider/>
            <List style={{height: '100%'}}>
                <h3 className={`${classes.ml3}`}>Меню</h3>
                <section style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '85%'}}>
                    <div>
                        {menuItems}
                    </div>
                    <div>
                        <ListItem
                            button
                            className={`${classes.ml2}`}
                            style={{backgroundColor: RoutePaths.educationalMaterials === location.pathname ? 'rgb(207 243 219 / 52%)' : ''}}
                            onClick={() => history.push(RoutePaths.educationalMaterials)}
                        >
                            <ListItemIcon>
                                <img src={`${process.env.PUBLIC_URL}/educational_materials_icon.svg`}/>
                            </ListItemIcon>
                            <p className={classes.menuItemText} >
                                Обучающие материалы
                            </p>
                        </ListItem>
                        <ListItem
                            button
                            className={`${classes.ml2}`}
                            style={{backgroundColor: RoutePaths.techSupport === location.pathname ? 'rgb(207 243 219 / 52%)' : '',}}
                            onClick={() => history.push(RoutePaths.techSupport)}
                        >
                            <ListItemIcon>
                                <img src={`${process.env.PUBLIC_URL}/tech_support.svg`}/>
                            </ListItemIcon>
                            <p className={classes.menuItemText}>
                                Техническая поддержка
                            </p>
                        </ListItem>
                        <ListItem
                            button
                            className={`${classes.ml2}`}
                            onClick={() => doLogout()}
                        >
                            <ListItemIcon>
                                <img src={`${process.env.PUBLIC_URL}/logout_menu_icon.svg`}/>
                            </ListItemIcon>
                            <p className={classes.menuItemText}>Выход</p>
                        </ListItem>
                        <div className={classes.textGray}>
                            <p>
                                Министерство здравоохранения и социальной защиты населения. © 2022
                            </p>
                            <p>All Rights Reserved</p>
                        </div>
                    </div>
                </section>
            </List>
        </Drawer>
    );
}

export default DrawerComponent;
