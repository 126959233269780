import PersonInfoForm from "./PersonInfoForm";
import {CancelReason} from "../../models/CancelReason";
import PersonVaccineForm from "./PersonVaccineForm";
import PersonAddressForm from "./PersonAddressForm";
import PersonCancelReasonForm from "./PersonCancelReasonForm";
import PersonDocumentForm from "./PersonDocumentForm";
import PersonInterPassForm from "./PersonInterPassForm";
import PersonJobForm from "./PersonJobForm";
import RegionRegistry from "../../utils/RegionRegistry";
import {Checkbox, Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Divider from "@mui/material/Divider";
import {useCallback, useContext, useMemo} from "react";
import {User} from "../../models/User";
import {UserContext} from "../../App";
import {UserRole} from "../../enums/UserRole";
import {array} from "yup";

const useStyles = makeStyles((theme: Theme) => ({
    buttonDefault: {
        "&.MuiButtonBase-root.MuiButton-root": {
            margin: theme.spacing(),
            backgroundColor: `#FFFFFF !important`,
            color: `#282828 !important`,
            borderRadius: "30px",
            width: "130px",
            fontFamily: "Montserrat&display=swap",
            fontSize: "12px",
            height: "40px",
        },
    },
    buttonCreate: {
        "&.MuiButtonBase-root.MuiButton-root": {
            margin: theme.spacing(),
            marginLeft: 0,
            backgroundColor: `${theme.palette.success.main} !important`,
            color: `#ffffff !important`,
            borderRadius: "30px",
            width: "130px",
            fontFamily: "Montserrat&display=swap",
            fontSize: "12px",
            height: "40px",
        },
    },
    buttonDelete: {
        "&.MuiButtonBase-root.MuiButton-root": {
            margin: theme.spacing(),
            backgroundColor: `${theme.palette.error.main}`,
            color: `#ffffff`,
            borderRadius: "30px",
            width: "130px",
            fontFamily: "Montserrat&display=swap",
            fontSize: "12px",
            height: "40px",
        },
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    ml1: {
        marginLeft: "1rem !important",
    },
    certificateBlock: {
        display: "flex",
        alignItems: "center",
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200,
    },
    button: {
        margin: theme.spacing(),
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
    },
    flexItems: {
        display: "flex",
        alignItems: "center",
    },
    mb1: {
        marginBottom: "1rem",
    },
    mb2: {
        marginBottom: "2rem",
    },
    mt2: {
        marginTop: "2rem",
    },
    sectionTitle: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#000000",
    },
    main: {
        backgroundColor: "white",
        padding: "1rem 2rem 1rem 2rem",
        borderRadius: "30px",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 1rem 5rem"
        }
    }
}));

interface Props {
    children?: any;
    control?: any;
    errors?: any;
    disabled?: boolean;
    handleAddNewVaccine: () => void;
    onDeleteVaccine: (index: number) => void;
    toggleRejectVaccine: () => void;
    onCancelReasonChange: (data: any) => void;
    setValue: (...args) => void;
    setInterPassportEnabled: (value: any) => void;
    watch: any;
    cancelled: boolean;
    interPassportEnabled: boolean;
    fields: any[];
    cancelReasonData: Partial<CancelReason>;
    id?: string | undefined
}

export default function PersonAddEditForm({
                                              children,
                                              control,
                                              errors,
                                              handleAddNewVaccine,
                                              setInterPassportEnabled,
                                              toggleRejectVaccine,
                                              onDeleteVaccine,
                                              onCancelReasonChange,
                                              setValue,
                                              fields,
                                              watch,
                                              cancelled,
                                              interPassportEnabled,
                                              cancelReasonData,
                                              disabled,
                                              id
                                          }: Props) {
    const classes = useStyles();
    const currentUser = useContext<User>(UserContext);
    const operatorDisabled = useMemo(() => currentUser?.role === UserRole.Operator && Boolean(id), [currentUser?.role, id]);

    return (
        <div className={classes.main}>
            <div>
                <h4 className={classes.sectionTitle}>Личные данные</h4>
            </div>
            <section className={classes.mb2}>
                <PersonInfoForm
                    disabled={disabled || operatorDisabled}
                    control={control}
                    errors={errors.person}
                />
                <Divider style={{marginTop: "2rem"}} flexItem/>
            </section>
            <section>
                <h3 className={classes.sectionTitle}>Документы</h3>
                <PersonDocumentForm
                    documentType={watch("document.documentType")}
                    control={control}
                    errors={errors.document}
                    disabled={disabled || operatorDisabled}
                />
                <Divider style={{marginTop: "2rem"}} flexItem/>
            </section>
            <section>
                <h3 className={classes.sectionTitle}>Адрес</h3>
                <PersonAddressForm
                    disabled={disabled || operatorDisabled}
                    cities={RegionRegistry.getDistrictsByRegion(watch("address.region"))}
                    control={control}
                    errors={errors.address}
                />
                <Divider style={{marginTop: "2rem"}} flexItem/>
            </section>
            <section>
                <h3 className={classes.sectionTitle}>Данные о работе</h3>
                <PersonJobForm
                    occupation={watch("job.occupation")}
                    control={control}
                    errors={errors.job}
                    disabled={disabled || operatorDisabled}
                />
                <Divider style={{marginTop: "2rem"}} flexItem/>
            </section>
            <section>
                <h3 className={classes.sectionTitle}>Вакцина</h3>
                <PersonVaccineForm
                    onAdd={handleAddNewVaccine}
                    onDelete={onDeleteVaccine}
                    disabled={cancelled || disabled}
                    control={control}
                    errors={errors.vaccines}
                    personVaccines={fields as any[]}
                    setValue={setValue}
                    cancelled={cancelled}
                    toggleRejectVaccine={() => toggleRejectVaccine()}
                />
                <Divider style={{marginTop: "2rem"}} flexItem/>
            </section>
            <div>
                <section>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3 className={classes.sectionTitle}>Причины отказа</h3>
                        {(!fields[0]?.id && !disabled) &&
                            <span style={{cursor: "pointer"}} onClick={() => toggleRejectVaccine()}>
                                <Checkbox
                                    disabled={disabled || cancelled}
                                    checked={cancelled}
                                    value={cancelled}
                                    color="primary"
                                />
                                Отказ
                            </span>}
                    </div>

                    {cancelled && <PersonCancelReasonForm
                        disabled={!cancelled || disabled}
                        cancelReason={cancelReasonData}
                        onChange={onCancelReasonChange}
                    />}
                    <Divider style={{marginTop: "2rem"}} flexItem/>
                </section>
            </div>
            <section>
                <div className={classes.sectionTitle}>
                    <div>
                        <h3>Заграничный паспорт</h3>
                    </div>
                    <div
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setInterPassportEnabled(!interPassportEnabled);
                        }}
                    >
                        <Checkbox
                            checked={interPassportEnabled}
                            value={interPassportEnabled}
                            color="primary"
                        />{" "}
                        Добавить
                    </div>
                </div>
                {interPassportEnabled && <PersonInterPassForm
                    control={control}
                    enabled={(interPassportEnabled && !disabled) || operatorDisabled}
                    errors={errors.internationalPassport}
                />}
            </section>
            <div>{children}</div>
        </div>
    );
}
