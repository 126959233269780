import React, {FC} from 'react';
import Typography from "@mui/material/Typography";
import VerticalTabs from "../tech-support/Tabs";

const EducationalMaterials: FC = () => {
    return (
        <div>
            <Typography variant='h4' align='center'>Обучающие материалы</Typography>

            <VerticalTabs/>
        </div>
    );
};

export default EducationalMaterials;