import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import { User } from "../../../models/User";
import { UserRole } from "../../../enums/UserRole";

const drawerWidth = 340;

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "#FFFFFF !important",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      height: "90px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.success.main,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiPaper-root-MuiDrawer-paper": {
        backgroundColor: "#FFFFFF !important",
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    profileBlock: {
      padding: "6px 26px 6px 20px",
      background: "#EEFFF3",
      borderRadius: "40px",
      color: theme.palette.success.main,
    },
    profileName: {
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: 800,
      color: theme.palette.success.main,
    },
    profileOrg: {
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontWeight: 400,
      color: "#282828",
      textAlign: "end",
    },
  };
});

interface Props {
  currentUser: User;
  open: boolean;
  toggleDrawer: () => void;
}
function AppBarComponent({ currentUser, toggleDrawer, open }: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          aria-label="open drawer"
          onClick={() => toggleDrawer()}
          edge="start"
          style={{ color: theme.palette.success.main }}
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.profileBlock}>
          <Typography variant="h6" noWrap>
            <p className={classes.profileName}>{currentUser?.name}</p>
            <p className={classes.profileOrg}>
              {currentUser?.role === UserRole.Admin
                ? "Админ"
                : currentUser?.organization.name}
            </p>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
