type NameAndValue = {
  name: string;
  value: string;
};
type Region = {
  districts: NameAndValue[];
  region: NameAndValue;
};

export default class RegionRegistry {
  private static list: Region[] = [
    {
      region: { name: "ш.Душанбе", value: "dushanbe" },
      districts: [
        {
          name: "Исмоили Сомони",
          value: "ismoil_somoni",
        },
        {
          name: "Сино",
          value: "sino",
        },
        {
          name: "Фирдавси",
          value: "firdavsi",
        },
        {
          name: "Шохмансур",
          value: "shohmansur",
        },
      ],
    },
    {
      region: { name: "ВМКБ", value: "vmkb" },
      districts: [
        {
          name: "ш.Хоруг",
          value: "khorug",
        },
        {
          name: "н.Мургоб",
          value: "murgob",
        },
        {
          name: "н.Ванч",
          value: "vanj",
        },
        {
          name: "н.Дарвоз",
          value: "darvoz",
        },
        {
          name: "н.Ишкошим",
          value: "ishkoshim",
        },
        {
          name: "н.Роштқалъа",
          value: "roshtkala",
        },
        {
          name: "н.Рушон",
          value: "rushon",
        },
        {
          name: "н.Шуғнон",
          value: "shugnon",
        },
      ],
    },
    {
      region: { name: "НТҶ", value: "ntj" },
      districts: [
        {
          name: "н.Рудакӣ",
          value: "rudaki",
        },
        {
          name: "н.Шаҳринав",
          value: "shahrinav",
        },
        {
          name: "н.Нуробод",
          value: "nurobod",
        },
        {
          name: "н.Файзобод",
          value: "fayzobod",
        },
        {
          name: "ш.Ҳисор",
          value: "hisor",
        },
        {
          name: "н.Сангвор",
          value: "sangvor",
        },
        {
          name: "н.Тоҷикобод",
          value: "tojikobod",
        },
        {
          name: "н.Лахш",
          value: "lakhsh",
        },
        {
          name: "н.Варзоб",
          value: "varzob",
        },
        {
          name: "ш.Ваҳдат",
          value: "vahdat",
        },
        {
          name: "ш.Рогун",
          value: "rogun",
        },
        {
          name: "ш.Турсунзода",
          value: "tursunzoda",
        },
        {
          name: "н.Рашт",
          value: "rasht",
        },
      ],
    },
    {
      region: { name: "Вилояти Хатлон", value: "khatlon" },
      districts: [
        {
          name: "ш.Кулоб",
          value: "kulob",
        },
        {
          name: "н. Шахритус",
          value: "shahritus",
        },
        {
          name: "н. Ҷ.Балхи",
          value: "j_balkhi",
        },
        {
          name: "ш. Левакант",
          value: "levakand",
        },
        {
          name: "н.Темурмалик",
          value: "temurmalik",
        },
        {
          name: "н.Ёвон",
          value: "yovon",
        },
        {
          name: "н.Фархор",
          value: "farkhor",
        },
        {
          name: "ш.Норак",
          value: "norak",
        },
        {
          name: "ш.Бохтар",
          value: "boktar",
        },
        {
          name: "н.Ш.Шохин",
          value: "sh_shokhin",
        },
        {
          name: "н.Ховалинг",
          value: "khovaling",
        },
        {
          name: "н.Панҷ",
          value: "panj",
        },
        {
          name: "н.Муъминобод",
          value: "muminobod",
        },
        {
          name: "н.А.Чоми",
          value: "a_jomi",
        },
        {
          name: "н.Вахш",
          value: "vakhsh",
        },
        {
          name: "н.Восеъ",
          value: "vose",
        },
        {
          name: "н.Дангара",
          value: "dangara",
        },
        {
          name: "н.Дустӣ",
          value: "dusti",
        },
        {
          name: "н.Кубодиён",
          value: "kubodien",
        },
        {
          name: "н.Кушониён",
          value: "kushonien",
        },
        {
          name: "н.Н.Хусрав",
          value: "n_khusrav",
        },
        {
          name: "н.Хамадонӣ",
          value: "khamadoni",
        },
        {
          name: "н.Балҷувон",
          value: "baljuvon",
        },
        {
          name: "н.Хуросон",
          value: "khuroson",
        },
        {
          name: "н.Ҷайҳун",
          value: "jayhun",
        },
      ],
    },
    {
      region: { name: "Сугд", value: "sugd" },
      districts: [
        {
          name: "ш.Хуҷанд",
          value: "khudjand",
        },
        {
          name: "н.Айни",
          value: "ayni",
        },
        {
          name: "н.Шахристон",
          value: "shahriston",
        },
        {
          name: "н.Зафаробод",
          value: "zafarobod",
        },
        {
          name: "ш.Бустон",
          value: "buston",
        },
        {
          name: "ш.Истаравшан",
          value: "istaravshan",
        },
        {
          name: "ш.Конибодом",
          value: "konibodom",
        },
        {
          name: "н.К.Масчоҳ",
          value: "k_mastchoh",
        },
        {
          name: "н.Ашт",
          value: "asht",
        },
        {
          name: "н.Б.Гафуров",
          value: "b_gafurov",
        },
        {
          name: "ш.Гулистон",
          value: "guliston",
        },
        {
          name: "н.Деваштич",
          value: "devashtich",
        },
        {
          name: "ш.Истиқлол",
          value: "istiklol",
        },
        {
          name: "ш.Исфара",
          value: "isfara",
        },
        {
          name: "н.Мастчоҳ",
          value: "mastchoh",
        },
        {
          name: "н.Спитамен",
          value: "spitamen",
        },
        {
          name: "н.Ҷ.Расулов",
          value: "j_rasulov",
        },
        {
          name: "ш.Панҷакент",
          value: "panjakent",
        },
      ],
    },
  ];

  static getList() {
    return this.list;
  }

  static getDistrictsByRegion(region: string): NameAndValue[] | undefined {
    if (!region) return [];
    return this.list.find((result) => result.region.value === region)
      ?.districts;
  }

  static filterDistrictsByRegion(region: Array<string>) {
    if (!region) return [];
    return this.list.filter(result => region.includes(result.region.value)).flat().map(el => el.districts).flat();
  }
}
