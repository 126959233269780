import {FormControl, InputLabel, MenuItem, Select, TextField, Theme,} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import {Control, Controller} from "react-hook-form";
import {useCallback, useContext} from "react";
import {UserContext} from "../../App";
import {User} from "../../models/User";
import {UserRole} from "../../enums/UserRole";

const useStyles = makeStyles((theme: Theme) => ({
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginTop: '0 !important',
        marginBottom: '10px !important',
        width: 300,
        "& .MuiOutlinedInput-root": {
            borderRadius: "30px",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
            color: `${theme.palette.primary.main} !important`,
        }
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 300,
    },
    mb2: {
        marginBottom: "1rem",
        marginTop: '8px'
    },

    selectMenu: {
        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
            width: "250px",
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
            borderRadius: "30px !important",
        },

        "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
            color: `${theme.palette.primary.main}`,
        },
    },
}));

interface IProps {
    errors: any;
    disabled?: boolean;
    control: Control<any, any>;
}

export default function PersonInfoForm({
                                           errors,
                                           control,
                                           disabled = false,
                                       }: IProps) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const handleInnChange = useCallback((value, onChange) => {
        if (!value) {
            onChange("");
            return;
        }
        if (isNaN(Number(value))) {
            if (value.length === 1) {
                onChange("");
                return;
            }
            onChange(value.slice(0, value.length - 1));
            return;
        }
        onChange(value);
    }, []);

    return (
        <div>
            <div>
                <Controller
                    name="person.nationalNumber"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            id="standard-required"
                            size="small"
                            className={classes.textField}
                            onChange={onChange}
                            value={value}
                            name="nationalNumber"
                            placeholder="Национальный №"
                            margin="normal"
                            label="Национальный №"
                            autoComplete="none"
                            type="text"
                            inputMode="numeric"
                            ref={ref}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    name="person.inn"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            id="standard-required"
                            label="ИНН"
                            size="small"
                            autoComplete="none"
                            className={classes.textField}
                            onChange={(e) => handleInnChange(e.target.value, onChange)}
                            value={value}
                            name="inn"
                            placeholder="ИНН"
                            margin="normal"
                            type="text"
                            ref={ref}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    name="person.lastName"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            required
                            id="standard-required"
                            label="Фамилия"
                            size="small"
                            className={classes.textField}
                            onChange={onChange}
                            value={value}
                            name="lastName"
                            margin="normal"
                            ref={ref}
                            error={!!errors?.lastName}
                            helperText={errors?.lastName?.message}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    name="person.firstName"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            required
                            id="standard-required"
                            label="Имя"
                            size="small"
                            className={classes.textField}
                            onChange={onChange}
                            value={value}
                            name="firstName"
                            margin="normal"
                            error={!!errors?.firstName}
                            helperText={errors?.firstName?.message}
                            ref={ref}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div className={classes.mb2}>
                <Controller
                    name="person.middleName"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            id="standard-required"
                            label="Отчество"
                            size="small"
                            className={classes.textField}
                            onChange={onChange}
                            value={value}
                            name="middleName"
                            margin="normal"
                            ref={ref}
                            error={!!errors?.middleName}
                            helperText={errors?.middleName?.message}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div>
                <Controller
                    name="person.birthDay"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <TextField
                            id="date"
                            label="Дата рождения"
                            size="small"
                            type="date"
                            required
                            name="birthDay"
                            value={value || ""}
                            className={classes.textField}
                            onChange={onChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors?.birthDay}
                            helperText={errors?.birthDay?.message}
                            ref={ref}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <div className={classes.mb2}>
                <Controller
                    name="person.gender"
                    control={control}
                    render={({field: {onChange, value, ref}}) => (
                        <FormControl className={classes.formControl}>
                            <InputLabel style={{top: '-8px'}} id="gender-label">Пол *</InputLabel>
                            <Select
                                labelId="gender-label"
                                id="gender"
                                size="small"
                                label="Пол *"
                                value={value}
                                onChange={onChange}
                                className={classes.selectMenu}
                                required
                                inputProps={{name: "gender"}}
                                ref={ref}
                                error={!!errors?.gender}
                                disabled={disabled}
                            >
                                <MenuItem value="">Не выбрано</MenuItem>
                                <MenuItem value="male">Мужчина</MenuItem>
                                <MenuItem value="female">Женщина</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
                {errors?.gender && (
                    <FormHelperText error>{errors.gender?.message}</FormHelperText>
                )}
            </div>
        </div>
    );
}
