import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  certificateBlock: {
    display: "flex",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
  buttonDefault: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      backgroundColor: `#FFFFFF !important`,
      color: `#282828 !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  buttonCreate: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      marginLeft: 0,
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `#ffffff !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  buttonPassport: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: theme.spacing(),
      marginLeft: 0,
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `#ffffff !important`,
      borderRadius: "30px",
      width: "130px",
      fontFamily: "Montserrat&display=swap",
      fontSize: "12px",
      height: "40px",
    },
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb2: {
    marginBottom: "1rem",
  },
  sectionTitle: {
    border: "1px solid lightgrey",
    padding: "10px",
    background: "lightgrey",
  },
}));
