import { useMemo } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import RegionRegistry from "../../utils/RegionRegistry";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller, Control } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  selectMenu: {
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "250px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "30px !important",
    },

    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main}`,
    },
  },
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb1: {
    marginBottom: "1rem",
  },
}));

interface IProps {
  errors: any;
  disabled?: boolean;
  cities: { name: string; value: string }[];
  control: Control<any, any>;
}

export default function PersonAddressForm({
  errors,
  control,
  cities,
  disabled = false,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const regions = useMemo(() => {
    return RegionRegistry.getList();
  }, []);

  return (
    <div>
      <div className={classes.mb1}>
        <Controller
          name="address.region"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <FormControl className={classes.formControl}>
              <InputLabel id="region-label">Область *</InputLabel>
              <Select
                labelId="region-label"
                label="Область *"
                id="region-simple"
                className={classes.selectMenu}
                value={value || ""}
                onChange={onChange}
                required
                disabled={disabled}
                inputProps={{
                  name: "region",
                }}
                error={!!errors?.region}
                ref={ref}
              >
                <MenuItem value="">Не выбрано</MenuItem>
                {regions.map((result) => (
                  <MenuItem
                    key={result.region.value}
                    value={result.region.value}
                  >
                    {result.region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {errors?.region && (
          <FormHelperText error>{errors?.region?.message}</FormHelperText>
        )}
      </div>
      <div>
        <Controller
          name="address.district"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <FormControl className={classes.formControl}>
              <InputLabel id="city-label">Город/Район *</InputLabel>
              <Select
                labelId="city-label"
                label="Город/Район *"
                id="city-simple"
                className={classes.selectMenu}
                value={value || ""}
                onChange={onChange}
                required
                disabled={disabled}
                // disabled={!data.district}
                inputProps={{
                  name: "district",
                }}
                error={!!errors?.city}
                ref={ref}
              >
                <MenuItem value="">Не выбрано</MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city.value} value={city.value}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {errors?.city && (
          <FormHelperText error>{errors.city?.message}</FormHelperText>
        )}
      </div>
      <div>
        <Controller
          name="address.address"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required
              id="standard-required"
              label="Адрес"
              disabled={disabled}
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="address"
              margin="normal"
              error={!!errors?.address}
              helperText={errors?.address?.message}
              ref={ref}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="address.phone"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              id="standard-required"
              label="Телефон"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              disabled={disabled}
              name="phone"
              margin="normal"
              type="number"
              inputMode="tel"
              error={!!errors?.phone}
              helperText={errors?.phone?.message}
              ref={ref}
            />
          )}
        />
      </div>
    </div>
  );
}
