import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller, Control } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 300,
    "& .MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  button: {
    margin: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 300,
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
  },
  mb2: {
    marginBottom: "1rem",
  },
  selectMenu: {
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "250px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: "30px !important",
    },

    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.primary.main}`,
    },
  },
}));

const occupations = [
  {
    name: "Не выбрано",
    value: "",
  },
  {
    name: "Безработный",
    value: 0,
  },
  {
    name: "Рабочий",
    value: 1,
  },
  {
    name: "Гос. Служащий",
    value: 2,
  },
  {
    name: "Предприниматель",
    value: 3,
  },
  {
    name: "Дехканин/фермер",
    value: 4,
  },
  {
    name: "Трудовой мигрант в Таджикистане",
    value: 5,
  },
  {
    name: "Трудовой мигрант за рубежом",
    value: 6,
  },
  {
    name: "Пенсионер",
    value: 7,
  },
  {
    name: "Домохозяйка или уход за инвалидами и детьми",
    value: 8,
  },
  {
    name: "Учеба",
    value: 9,
  },
  {
    name: "Разнорабочий/наёмный рабочий",
    value: 10,
  },
  {
    name: "Другое",
    value: 11,
  },
];

interface IProps {
  errors: any;
  disabled?: boolean;
  control: Control<any, any>;
  occupation: number;
}

export default function PersonJobForm({
  errors,
  control,
  occupation,
  disabled = false,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="occupation-simple">Род деятельности *</InputLabel>
          <Controller
            name="job.occupation"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                labelId="occupation-label"
                label="Род деятельности *"
                id="occupation-simple"
                className={classes.selectMenu}
                value={value}
                onChange={onChange}
                required
                disabled={disabled}
                autoWidth
                inputProps={{
                  name: "occupation",
                }}
                error={!!errors?.occupation}
                ref={ref}
              >
                {occupations.map((occupation) => {
                  return (
                    <MenuItem key={occupation.value} value={occupation.value}>
                      {occupation.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </FormControl>
        {errors?.occupation && (
          <FormHelperText error>{errors.occupation?.message}</FormHelperText>
        )}
      </div>
      <div>
        <Controller
          name="job.position"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={Boolean(occupation)}
              disabled={!occupation || disabled}
              id="standard-required"
              label="Должность"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="position"
              margin="normal"
              error={!!errors?.position}
              helperText={errors?.position?.message}
              ref={ref}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="job.placeOfWork"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              required={Boolean(occupation)}
              disabled={!occupation || disabled}
              id="standard-required"
              label="Место работы"
              className={classes.textField}
              onChange={onChange}
              value={value || ""}
              name="placeOfWork"
              margin="normal"
              error={!!errors?.placeOfWork}
              helperText={errors?.placeOfWork?.message}
              ref={ref}
            />
          )}
        />
      </div>
    </div>
  );
}
